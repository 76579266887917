import React, {useState} from "react";
import PropTypes from "prop-types";
import {BlockFooter} from "framework7-react";
import Countdown from "react-countdown";

export const ResendOtp = ({onResend, countdown}) => {

  const [isCounting, setCounting] = useState(true);

  const renderer = ({ formatted, completed }) => {
    if (completed) {
      setCounting(false);
      return "";
    }
    return <p>Kirim OTP kembali dalam {formatted.minutes}:{formatted.seconds}</p>;
  };

  const resend = () => {
    setCounting(true);
    onResend();
  };

  return (
    <BlockFooter style={{ textAlign: "center", marginTop: "3px" }}>
      {isCounting
        ? <Countdown date={Date.now() + (countdown * 1000)} renderer={renderer}/>
        : <p>Tidak menerima kode verifikasi? <a onClick={() => resend()}>Kirim ulang</a></p>
      }
    </BlockFooter>
  );
}

ResendOtp.defaultProps = {
  countdown: 300,
  onResend: () => {},
}

ResendOtp.propTypes = {
  countdown: PropTypes.number,
  onResend: PropTypes.func,
}

export default ResendOtp;
