import React from "react";
import PropTypes from "prop-types";
import { List, ListInput } from "framework7-react";

import {schemaProfileItem} from "data/profile";
import {dateFormat} from "utils/date";

import styles from "./profile.module.css";

const ProfileItem = ({data}) => {
  let profile = {};
  const {social_media = {}} = data || {};
  
  profile = {
    ...data
  };

  if (typeof social_media === "string") {
    profile = {
      ...profile,
      ...JSON.parse(social_media)
    };
  } else {
    profile = {
      ...profile,
      ...social_media
    };
  }

  const birthDate = profile.birth_date ? dateFormat({date: profile.birth_date}) : "";

  const setValue = (name) => {
    switch (name) {
    case "birth_place":
      return profile.birth_place ? `${profile.birth_place}, ${birthDate}` : birthDate;
    case "domisili":
      return profile.district_id ? `${profile.district_name}, ${profile.regency_name}, ${profile.province_name}` : "";
    case "enrolled_programs":
      return profile.enrolled_programs ? profile.enrolled_programs.join(", ") : "";
    case "batch":
      if (profile.batch) {
        const year = [...profile.batch].slice(0, 4);
        const batch = [...profile.batch].slice(profile.batch.length - 1, profile.batch.length);
        return `${year.join("")} Batch ${batch.join("")}`;
      } else return "";
    default:
      return profile[name] || "";
    }
  };

  return (
    <List>
      {schemaProfileItem.map((row, key) => (
        <ListInput
          key={key}
          className={styles.profileInput}
          label={(
            <div
              dangerouslySetInnerHTML={{__html: row.label}}
            />
          )}
          type={row.type}
          value={setValue(row.name)}
          placeholder={row.placeholder}
          disabled={true}
        />
      ))}
    </List>
  );
};

ProfileItem.defaultProps = {
  data: {}
};

ProfileItem.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ProfileItem;
