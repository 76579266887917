import React, {useState} from "react";
import {f7, Page, Popup} from "framework7-react";
import ReactGA from "react-ga";

import {useAuth} from "contexts/auth";
import {SharedStateContext} from "contexts/sharedState";
import {AuthService} from "services";

import SignupForm from "./signup-form";
import OtpForm from "./otp-form";
import LoginForm from "./login-form";
import PropTypes from "prop-types";

export const LoginPopup = ({id, closeable}) => {
  const screen = {
    LOGIN: "login",
    SIGNUP: "signup",
    OTP: "otp",
  };

  const {setSession} = useAuth();

  const [activeScreen, setScreen] = useState(screen.LOGIN);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [personalData, setPersonalData] = useState(null);

  const reset = () => {
    setScreen(screen.LOGIN);
    ReactGA.event({
      category: "Modal",
      action: "Close Login"
    });
  };

  const login = (phoneNumber) => {
    setPhoneNumber(phoneNumber);
    AuthService.requestOtp(phoneNumber).then((response) => {
      if (!response.error) {
        ReactGA.event({
          category: "User",
          action: "Sign in",
          label: phoneNumber
        });
        setScreen(screen.OTP);
      }
      else if (response.error === "user is not registered.") setScreen(screen.SIGNUP);
      else f7.dialog.alert(response.error, "Oops!");
    });
  };

  const signup = (payload) => {
    setPersonalData(payload);
    AuthService.requestOtp(phoneNumber, payload).then((response) => {
      if (!response.error) {
        ReactGA.event({
          category: "User",
          action: "Sign up",
          label: phoneNumber
        });
        setScreen(screen.OTP);
      }
      else f7.dialog.alert(response.error, "Oops!");
    });
  };

  const otpCallback = (result) => {
    AuthService.callbackAuth(result).then((data) => {
      setSession(data);
      const toastBottom = f7.toast.create({
        text: "Login berhasil",
        closeTimeout: 2000,
      });
        // Open it
      toastBottom.open();

    });
    f7.popup.close();
  };

  const conditionalRendering = () => {
    switch (activeScreen) {
    case screen.LOGIN: return <LoginForm onSubmit={login} />;
    case screen.SIGNUP: return <SignupForm onSubmit={signup} onBack={() => setScreen(screen.LOGIN)} />;
    case screen.OTP: return <OtpForm onVerified={otpCallback} onBack={() => setScreen(screen.LOGIN)} />;
    }
  };

  return (
    <Popup id={id} className="inset-popup" closeByBackdropClick={closeable} swipeToClose={closeable ? "to-bottom" : false} onPopupClosed={() => reset()}>
      <Page loginScreen>
        <div slot="fixed" className="swipe-handler" />
        <SharedStateContext.Provider value={{phoneNumber, personalData}}>
          {conditionalRendering()}
        </SharedStateContext.Provider>
      </Page>
    </Popup>
  );
};

LoginPopup.defaultProps = {
  id: "login-screen",
  closeable: true,
};

LoginPopup.propTypes = {
  id: PropTypes.string.isRequired,
  closeable: PropTypes.bool.isRequired,
}

export default LoginPopup;
