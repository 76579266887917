import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import {
  Page,
  Navbar as NavbarCore,
  ListInput,
  Popup,
  NavRight,
  Link,
  f7,
  List,
  ListItem,
  Block,
  Preloader
} from "framework7-react";
import ReactGA from "react-ga";

import Navbar from "components/navbar/";
import {useAuth} from "contexts/auth";
import {DataService, AuthService} from "services";
import sentryWriteLog from '../../../utils/sentry'
import {gender, have_enrolled_before} from "data/static";
import {dateFormat} from "utils/date";

import FormEditProfile from "./form-edit-profile";
import styles from "./styles.module.css";
import { Fragment } from "react";

const ProfileEditPage = ({f7router}) => {
  const {query = {}} = f7router.currentRoute || {};
  const {from = null, programId = null} = query || {};
  const {session, setSession} = useAuth();
  const {user = {}} = session || {};

  const [errors, setError] = useState({});
  const [profile, setProfile] = useState(null);
  const [birthDate, setBirthDate] = useState(null);
  const [districts, setDistricts] = useState(null);
  const [suggestions, setSuggestions] = useState({ gender, have_enrolled_before });
  const [departments, setDepartments] = useState([]);
  const [domicilies, setDomicilies] = useState(null);
  const [loading, setLoading] = useState(false);
  const [popup, setOpenPopup] = useState({
    field_of_work: false,
    district_id: false,
    education_subject: false
  });
  const [validInput, setValidInput] = useState({
    birth_place: true,
    emergency_contact_number: true,
    emergency_contact_relation: true,
    education_subject: true,
    email: true,
    address: true
  });

  useEffect(() => {
    if (session) {
      const {
        social_media = {},
        education_grade = "SD",
        education_subject,
        district_id,
        district_name,
        regency_name,
        province_name,
        have_enrolled_before = false
      } = user.profile || {};
      const notHaveEducationSubject = ["SD", "SMP", "SMA", null];
      const [genderFilter = {key: "M"}] = gender.filter((gender) => gender.label === user.profile.gender);

      if (typeof social_media === "string") {
        setProfile({
          ...profile,
          ...user.profile,
          ...JSON.parse(social_media),
          education_subject: notHaveEducationSubject.includes(education_grade) ? "-" : education_subject,
          gender: genderFilter.key,
          have_enrolled_before: have_enrolled_before ? 1 : 2
        });
        setBirthDate(user.profile.birth_date);
        setDistricts(district_id ? `${district_name}, ${regency_name}, ${province_name}` : "");
      } else {
        setProfile({
          ...profile,
          ...user.profile,
          ...social_media,
          education_subject: notHaveEducationSubject.includes(education_grade) ? "-" : education_subject,
          gender: genderFilter.key,
          have_enrolled_before: have_enrolled_before ? 1 : 2
        });
        setBirthDate(user.profile.birth_date);
        setDistricts(district_id ? `${district_name}, ${regency_name}, ${province_name}` : "");
      }
    }
  }, [session]);

  useEffect(() => {
    ReactGA.pageview("/profile/edit");

    const occupations = onGetSuggestion("field_of_work");
    const educationGrades = onGetSuggestion("education_grade");
    const bloodTypes = onGetSuggestion("blood_type");
    const educationSubject = onGetSuggestion("education_subject");
    const previousBatch = onGetSuggestion("previous_batch");

    Promise.all([
      educationGrades,
      bloodTypes,
      occupations,
      educationSubject,
      previousBatch
    ]).then((response) => {
      let suggestionData = {};
      response.forEach((item) => {
        suggestionData = {
          ...suggestionData,
          [item.group]: item.data
        };
      });
      setSuggestions({
        ...suggestions,
        ...suggestionData
      });
    });

    onGetDepartments();
  }, []);

  const onGetSuggestion = async (group) => {
    const path = `/v1/suggestion-groups/${group}`;
    const response = await DataService.get(path);
    if (response.items) {
      return {
        group,
        data: Array.isArray(response.items) ? response.items.map((row) => ({
          key: row.item,
          label: row.display_text
        })) : []
      };
    } else {
      return {
        group,
        data: []
      };
    }
  };

  const onGetDepartments = async () => {
    const response = await DataService.get("/v1/departments?limit=-1");
    if (response.data) {
      setDepartments(response.data.map((row) => ({
        key: row.code,
        label: row.code
      })));
    }
  };

  const onChange = (e) => {
    const notHaveEducationSubject = ["SD", "SMP", "SMA", null];
    const {name, value} = e.target;
    onValidate(e);

    if (name === "education_grade" && notHaveEducationSubject.includes(value)) {
      const newProfile = {
        ...profile,
        education_subject: "-",
        [name]: value
      };
      setProfile(newProfile);
      
    } else {
      let newProfile = {
        ...profile,
        [name]: value
      };

      if (name === "first_time_program") {
        newProfile = {
          ...profile,
          ...newProfile,
          enrolled_programs: [value]
        };
      }

      setProfile(newProfile);
    }
  };

  const onChangeRadio = ({name, value}) => {
    if (name === "gender") {
      setProfile({
        ...profile,
        [name]: value,
      });
    } else {
      setProfile({
        ...profile,
        have_enrolled_before: value,
        enrolled_programs: value == 1 ? ["BMAQ"] : [],
        first_time_program: value == 1 ? "BMAQ" : null,
        batch: value == 1 ? "202002" : null,
      });
    }
  };

  const onChangeCheckbox = (e) => {
    const {name, value, checked} = e.target;
    const {enrolled_programs = []} = profile || {};

    if (checked) {
      setProfile({
        ...profile,
        [name]: [...enrolled_programs, value]
      });
    } else if (!checked && profile.first_time_program != value) {
      setProfile({
        ...profile,
        [name]: enrolled_programs.filter((row) => row != value)
      });
    }
  };

  const onChangeDate = (e) => setBirthDate(e.target.value);

  const onValidateInput = (e) => {
    setValidInput({
      ...validInput,
      [e.name]: e.isValid
    });
  };

  const onFocus = async ({name}) => {
    const {education_grade = "SD"} = profile || {};
    const notHaveEducationSubject = ["SD", "SMP", "SMA", null];

    if (name === "field_of_work") {
      setOpenPopup({
        field_of_work: true,
        district_id: false,
        education_subject: false,
      });
    } else if (name === "districts") {
      setOpenPopup({
        field_of_work: false,
        education_subject: false,
        district_id: true,
      });
    } else if (name === "education_subject" && !notHaveEducationSubject.includes(education_grade)) {
      setOpenPopup({
        field_of_work: false,
        education_subject: true,
        district_id: false,
      });
    }
  };

  const onSearchDomisili = async (e) => {
    const query = e.target.value;
    if (query.length >= 3) {
      try {
        setLoading(true);
        const path = `/v1/domiciles?q=${query}`;
        const {data = []} = await DataService.get(path);
        setLoading(false);
        setDomicilies(data);
      } catch(err) {
        setLoading(false);
        f7.dialog.alert(err, "Oops!");
      }
    } else {
      setDomicilies(null);
    }
  };

  const onSelectList = ({label, name, domicile_text, id}) => {
    setOpenPopup({
      field_of_work: false,
      education_subject: false,
      district_id: false,
    });
    if (name === "field_of_work" || name === "education_subject") {
      onChange({
        target: {
          name,
          value: label
        }
      });
    } else if (name === "districts") {
      setDistricts(domicile_text);
      onChange({
        target: {
          name: "district_id",
          value: id
        }
      });
    }
  };

  const onValidate = (e) => {
    const {name, value} = e.target;

    if ((name === "phone_number" || name === "emergency_contact_number") && value) {
      if (/^\d+$/.test(value) === false) {
        setError({
          ...errors,
          [name]: "Hanya boleh terdiri dari angka"
        });
      }
      if (value.length >= 3 && !(value.startsWith("08") || value.startsWith("628"))) {
        setError({
          ...errors,
          [name]: "Nomor harus diawali 08xxx atau 628xxx"
        });
      }
    } else if (name === "national_id" && value && value.length !== 16) {
      setError({
        ...errors,
        [name]: "Nomor KTP harus 16 karakter"
      });
    } else {
      setError({
        ...errors,
        [name]: ""
      });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const path = "/v1/user/profile";
    const payload = setPayload();
    
    f7.dialog.preloader();

    try {
      const response = await DataService.put(path, payload)
      setProfileData()
    } catch(e) {
      sentryWriteLog({
        title: 'Failed put update profile data',
        result: e,
      })
      f7.dialog.close();
      f7.dialog.alert("Terjadi kesalahan, silahkan kontak admin atau CP", "Oops!");
    }
  };

  const setPayload = () => ({
    address: profile.address,
    birth_date: birthDate && dateFormat({
      date: birthDate[0],
      formatDate: "yyyy-MM-dd"
    }),
    birth_place: profile.birth_place,
    district_id: profile.district_id,
    education_grade: profile.education_grade || "SD",
    education_subject: profile.education_subject,
    email: profile.email,
    field_of_work: profile.field_of_work,
    fullname: profile.fullname,
    gender: profile.gender,
    nickname: profile.nickname,
    phone_number: profile.phone_number,
    emergency_contact_relation: profile.emergency_contact_relation,
    emergency_contact_number: profile.emergency_contact_number,
    blood_type: profile.blood_type || "A",
    national_id: profile.national_id,
    have_enrolled_before: profile.have_enrolled_before == 1,
    batch: profile.batch || null,
    enrolled_programs: profile.enrolled_programs || [],
    first_time_program: profile.first_time_program || null,
  });

  const setTitlePopup = () => {
    if (popup.district_id) {
      return "Domisili";
    } else if (popup.education_subject) {
      return "Jurusan";
    } else if (popup.field_of_work) {
      return "Pekerjaan";
    }
  };

  const setProfileData = () => {
    const auth = {
      expires_in: session.expired_at,
      access_token: session.token
    };

    AuthService.callbackAuth(auth).then((data) => {
      if (data) {
        setSession(data);
        f7.dialog.close();
        f7.dialog.alert("Perubahan data berhasil", "Yeay!", () => {
          if (from === "program-detail" && programId) {
            f7router.navigate(`/program-detail/${programId}?from=profile`, {force: true });
          } else {
            f7router.navigate("/profile", {force: true });
          }
        });
      }
    });
  };

  return (
    <Page name="profile-edit">
      <Navbar back="/profile">Kembali</Navbar>
      <Popup
        opened={popup.district_id || popup.field_of_work || popup.education_subject}
        onPopupClosed={() => {
          setOpenPopup({
            field_of_work: false,
            education_subject: false,
            district_id: false,
          });
          setDomicilies(null);
        }}
      >
        <Page>
          <NavbarCore title={setTitlePopup()}>
            <NavRight>
              <Link popupClose>Close</Link>
            </NavRight>
          </NavbarCore>
          <List
            simpleList={popup.field_of_work || popup.education_subject}
            className={styles.list}
            mediaList={popup.district_id}
            inset={popup.district_id}
          >
            {popup.district_id && (
                <ListInput
                  type="text"
                  placeholder="Cari Kecamatan"
                  className={styles.searchInput}
                  onChange={onSearchDomisili}
                />
            )}
            {popup.district_id ? (
              loading ? 
                <Block className="text-align-center">
                  <Preloader 
                    size={42} 
                    className="padding-top" 
                  />
                </Block> :
                domicilies ? 
                  domicilies.length ? 
                    domicilies.map((row, key) => {
                      const splitDomisili = row.domicile_text.split(",");
                      const subtitle = `${splitDomisili[1]}, ${splitDomisili[2]}`;
                      return (
                        <ListItem
                          key={key}
                          title={row.name}
                          subtitle={subtitle}
                          className={styles.domicileList}
                          onClick={() => onSelectList({...row, name: "districts"})}
                        />
                      );
                    }) : 
                    <Block className="text-align-center">
                      <img
                        className={styles.districtIllustration}
                        src="https://i.ibb.co/g6MnWvK/no-data.png"
                        alt="Data tidak ditemukan."
                      />
                      <p>
                        Data tidak ditemukan.{' '}
                      </p>
                    </Block> : 
                  <Block className="text-align-center">
                    <img
                      className={styles.districtIllustration}
                      src="https://i.ibb.co/tMMpfNk/select-house.png"
                      alt="Ketik nama kecamatan kamu di atas."
                    />
                    <p>
                      Ketik nama kecamatan kamu di atas.{' '}
                    </p>
                  </Block>
            ) : (
              popup.field_of_work ?
                suggestions.field_of_work && suggestions.field_of_work.map((row, key) => (
                  <ListItem
                    key={key}
                    title={row.label}
                    onClick={() => onSelectList({...row, name: "field_of_work"})}
                  />
                )) : suggestions.education_subject && suggestions.education_subject.map((row, key) => (
                  <ListItem
                    key={key}
                    title={row.label}
                    onClick={() => onSelectList({...row, name: "education_subject"})}
                  />
                ))
            )}
          </List>
        </Page>
      </Popup>
      {profile && (
        <FormEditProfile
          errors={errors}
          validInput={validInput}
          suggestions={{...suggestions, departments}}
          data={{
            ...profile,
            birth_date: birthDate,
            districts
          }}
          event={{
            onFocus,
            onChange,
            onSubmit,
            onChangeDate,
            onValidateInput,
            onChangeRadio,
            onChangeCheckbox
          }}
        />
      )}
    </Page>
  );
};

ProfileEditPage.defaultProps = {
  f7router: {}
};

ProfileEditPage.propTypes = {
  f7router: PropTypes.object,
};

export default ProfileEditPage;
