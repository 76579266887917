import React, {useEffect, useState} from "react";
import {Block, BlockHeader, Button, List, ListInput, LoginScreenTitle} from "framework7-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";

import PropTypes from "prop-types";

export const LoginForm = ({onSubmit}) => {

  const [phoneNumber, setPhoneNumber] = useState("");
  const [error, setError] = useState("");

  const isPhoneNumberValid = () => {
    if (phoneNumber.length < 10) return false;
    return error.length === 0;
  };

  const onFormSubmitted = (e) => {
    e.preventDefault();
    if (isPhoneNumberValid()) onSubmit(phoneNumber);
  }

  // phoneNumber validation
  useEffect(() => {
    if (phoneNumber.length > 0) {
      if (/^\d+$/.test(phoneNumber) === false) {
        return setError("Hanya boleh terdiri dari angka");
      }
      if (phoneNumber.length >= 3 && !(phoneNumber.startsWith("08") || phoneNumber.startsWith("628"))) {
        return setError("Nomor hasur diawali 08xxx atau 628xxx");
      }
    }
    setError("");
  }, [phoneNumber]);

  return (
    <>
      <LoginScreenTitle>Daftar / Masuk</LoginScreenTitle>
      <BlockHeader>Masukkan nomor whatsapp kamu untuk melanjutkan</BlockHeader>
      <Block>
        <form onSubmit={onFormSubmitted}>
          <List inlineLabels>
            <ListInput
              type="tel"
              placeholder="08123456789"
              clearButton
              value={phoneNumber}
              maxlength="14"
              onInput={(e) => setPhoneNumber(e.target.value)}
              errorMessageForce={error.length > 0}
              errorMessage={error}
              className="input-phone"
            >
              <FontAwesomeIcon
                icon={faWhatsapp}
                size="2x"
                color="#21884d"
                slot="media"
              />
            </ListInput>
          </List>
        </form>
      </Block>
      <Block>
        <Button fill disabled={!isPhoneNumberValid()} onClick={() => onSubmit(phoneNumber)}>Lanjut</Button>
      </Block>
    </>
  );
}

LoginForm.defaultProps = {
  onSubmit: () => {}
}

LoginForm.propTypes = {
  onSubmit: PropTypes.func,
}

export default LoginForm;
