import React, {useState, useEffect, useCallback} from "react";
import {Page} from "framework7-react";
import ReactGA from "react-ga";

import {useAuth} from "contexts/auth";
import Navbar from "components/navbar/";
import {toBase64, getCroppedImg} from "utils/file";
import {DataService} from "services";

import ProfileHeader from "./profile-header";
import ProfilTitle from "./profile-title";
import ProfileItem from "./profile-item";
import styles from "./profile.module.css";

const ProfilePage = () => {
  const {session} = useAuth();
  const {user = {}} = session || {};
  const {profile = {}} = user || {};
  const [avatar, setAvatar] = useState("https://ui-avatars.com/api/?name=");
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [popupOpened, setPopupOpened] = useState(false);
  const [fileUpload, setFileUpload] = useState("");
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  useEffect(() => {
    if (session) {
      const defaultAvatar = `https://ui-avatars.com/api/?name=${profile.fullname || ""}`;
      setAvatar(profile.photo || defaultAvatar);
    }
  }, [session]);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onUploadAvatar = () => {
    document.getElementById("avatar-file").click();
  };

  const onRemoveAvatar = () => {
    const defaultAvatar = `https://ui-avatars.com/api/?name=${profile.fullname || ""}`;
    setAvatar(defaultAvatar);
    DataService.delete("/v1/user/avatar");
  };

  const onChangeFile = async (e) => {
    const file = e.target.files[0];
    const result = await toBase64(file).catch(e => Error(e));
    if(result instanceof Error) {
      console.log("Error: ", result.message);
      return;
    }
    setFileUpload(result);
    setPopupOpened(true);
  };

  const onSaveImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        fileUpload,
        croppedAreaPixels,
      );
      DataService.put("/v1/user/avatar", {avatar: croppedImage});
      setAvatar(croppedImage);
      setFileUpload("");
      setCrop({ x: 0, y: 0 });
      setPopupOpened(false);
      setZoom(1);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  useEffect(() => {
    ReactGA.pageview("/profile");
  }, []);

  return (
    <Page name="profile" className={styles.pageContent}>
      <Navbar back="/">Kembali</Navbar>
      <ProfileHeader
        data={profile}
        avatar={avatar}
        fileUpload={fileUpload}
        popupOpened={popupOpened}
        onUploadAvatar={onUploadAvatar}
        onRemoveAvatar={onRemoveAvatar}
        onChangeFile={onChangeFile}
        onPopupClosed={() => setPopupOpened(false)}
        cropper={{
          crop,
          setCrop,
          zoom,
          setZoom,
          onSaveImage,
          onCropComplete
        }}
      />
      <div className={styles.profileBottomSheet}>
        <ProfilTitle data={profile} />
        <ProfileItem data={profile} />
      </div>
    </Page>
  );
};


export default ProfilePage;
