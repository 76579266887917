import * as Sentry from '@sentry/browser'

const sentryWriteLog = ({
  title,
  titleExtra,
  email = '',
  result,
  tags = [],
}) => {
  const isNetworkError = result.message === 'Network Error'

  if (!isNetworkError) {
    Sentry.configureScope((scope) => {
      scope.setExtra(titleExtra || title, {
        responseAPI: result,
        customer: email,
        date: `${new Date()}`,
      })
  
      scope.setUser({ email })
      scope.setTag(...tags)
    })
  
    Sentry.captureMessage(title)
  }

  return true
}

export default sentryWriteLog
