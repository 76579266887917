import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.module.css";

export const ProgramStatus = ({status}) => {
  switch (status) {
  case 1:
    return (
      <span className={`${styles.badge} ${styles.badgeWarning}`}>
        Menunggu Pembayaran
      </span>
    );
  case 2:
    return (
      <span className={`${styles.badge} ${styles.badgeDefault}`}>
        Menunggu Konfirmasi
      </span>
    );
  case 3:
    return (
      <span className={`${styles.badge} ${styles.badgeSuccess}`}>
        Terdaftar
      </span>
    );
  case 4:
    return (
      <span className={`${styles.badge} ${styles.badgeInfo}`}>
        Lulus
      </span>
    );
  }
};

ProgramStatus.propTypes = {
  status: PropTypes.number.isRequired,
};

const ProgramData = ({ name, batch, status }) => (
  <div className={`${styles.card} ${styles.cardProgramData}`}>
    <h3>Data Program</h3>
    <div className={styles.programWrapper}>
      <div>
        <p className={styles.label}>Program Studi</p>
        <h4>{name}</h4>
      </div>
      <p className={styles.programBatch}>{batch}</p>
    </div>
    <p className={styles.label}>Status</p>
    <ProgramStatus status={status} />
  </div>
); 

ProgramData.propTypes = {
  name: PropTypes.string.isRequired,
  batch: PropTypes.string.isRequired,
  status: PropTypes.number.isRequired,
};

export default ProgramData;