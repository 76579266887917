import React, {Fragment, useEffect, useState} from "react";
import PropTypes from "prop-types";
import {f7, Icon} from "framework7-react";

import {inRupiah} from "utils/currency";

import styles from "./styles.module.css";
import Tour from "reactour";

const Invoices = ({ data }) => {
  const [isTourOpen, setIsTourOpen] = useState(false);

  const tourConfig = [{
    selector: '[data-tour="invoice"]',
    content: 'Klik pada baris tagihan di atas untuk informasi pembayaran',
    position: 'bottom'
  }];

  const goToPayment = (item) => {
    if (!item.has_payment) {
      setIsTourOpen(false);
      f7.views.main.router.navigate('/payment/' + item.invoice_code, {force: true});
    } else {
      f7.dialog.alert("Kamu sudah mengunggah bukti pembayaran. Mohon menunggu konfirmasi dari kami", "Oops")
    }
  }

  const getStyleAmount = () => {
    if (item.amount != unpaid_amount) {
      return styles.amountPaymentH4Strikethrough
    } else {
      return ""
    }
  }

  useEffect(() => {
    if (window.location.search.indexOf("guide") !== -1) {
      setTimeout(() => {
        setIsTourOpen(true);
      }, 500)
    }
  }, []);

  return (
    <div className={`${styles.card}`}>
      <h3>Tagihan</h3>
      {data.map((item, key) => (
        <Fragment key={key}>
          <hr className={styles.divider} />
          <div data-tour={key === 0 ? 'invoice' : ''} onClick={() => goToPayment(item)} className="display-flex justify-content-space-between align-items-center padding-top-half padding-bottom-half">
            <div>
              <p className={styles.label}>{item.invoice_note}</p>
              <h4 className={`no-margin 
              ${item.amount != item.unpaid_amount ? styles.amountPaymentH4Strikethrough : ""}`}>
                {inRupiah(item.amount)}
              </h4>
              <h4 className={`no-margin ${
                item.amount != item.unpaid_amount ? styles.amountPaymentH4 : styles.amountPaymentH4Hidden
              }`}>
                {`${inRupiah(item.unpaid_amount)}`}
              </h4>
            </div>
            <div className="display-flex align-items-center">
              <div className={`${styles.badge} ${item.paid ? styles.badgeSuccess : styles.badgeWarning} margin-right`}>
                {item.paid ? "Lunas" : "Belum Lunas"}
              </div>
              {!item.has_payment && <Icon icon="icon-next" className={styles.iconNext} />}
            </div>
          </div>
        </Fragment>
      ))}
      <Tour
        showNumber={false}
        showButtons={false}
        showNavigation={false}
        showCloseButton={false}
        steps={tourConfig}
        isOpen={isTourOpen}
        onRequestClose={() => setIsTourOpen(false)}
      />
    </div>
  );
}

Invoices.propTypes = {
  data: PropTypes.array.isRequired,
};

export default Invoices;
