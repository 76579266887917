import React from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";

import CarouselBody from "./carousel-body";
import styles from "./program.module.css";

export const ProgramCarousel = ({ programs }) => {

  const countProgram = () => {
    switch (programs.length) {
      case 2:
        return 1
      default: return 3
    }
  }
  const settings = {
    dots: true,
    accessibility: true,
    infinite: true,
    speed: 500,
    slidesToShow: countProgram(),
    slidesToScroll: 1,
    centerPadding: '32px',
    centerMode: true,
    autoplay: false,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 993,
        settings: {
          slidesToShow: countProgram(),
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <Slider {...settings}>
      {programs.map((data, key) =>
        <div key={key} className={styles.swiperSlide}>
          <CarouselBody data={data} />
        </div>
      )}
    </Slider>
  );
};

ProgramCarousel.propTypes = {
  programs: PropTypes.array.isRequired,
};

export default ProgramCarousel;
