import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "framework7-react";

import styles from "./programDetailArticle.module.css";

export const SpecialHeader = ({title, subtitle, male_quota, female_quota}) => {
  return (
    <Row>
      <Col width="100" medium="60" xsmall="60">
        <h2>{title}</h2>
        <h5 className={styles.studyArticleMainHeaderSpecial}>{subtitle}</h5>
      </Col>
      <Col width="100" medium="40" xsmall="40" className={styles.studyArticleMainQuota}>
        <Row>
          <Col width="50">
            <h4>Sisa Kuota Ikhwan</h4>
            <div className={`${styles.studyArticleMainQuotaNumber} ${styles.bmaq}`}>{male_quota}</div>
          </Col>
          <Col width="50">
            <h4>Sisa Kuota Akhwat</h4>
            <div className={`${styles.studyArticleMainQuotaNumber} ${styles.bmaq}`}>{female_quota}</div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

SpecialHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  male_quota: PropTypes.number.isRequired,
  female_quota: PropTypes.number.isRequired
};

export default SpecialHeader;
