import React from "react";
import PropTypes from "prop-types";
import { Button, Icon } from "framework7-react";

import {ProgramStatus} from "../../program-enrollment/program-data";

import styles from "./program.module.css";

export const ProgramBody = ({
  id,
  program,
  status,
  goToDetailEnrollment,
  batch
}) => (
  <div className={styles.card} onClick={goToDetailEnrollment(id)}>
    <Button className={styles.buttonAction}>
      <Icon icon="icon-next" />
    </Button>
    <div className={styles.cardSubTitle}>
      <p>Program Studi</p>
    </div>
    <div className="row">
      <div className={styles.cardTitle}>
        <h2>{program.alt_name}</h2>
        <h4>{batch}</h4>
      </div>
    </div>
    <div className="display-flex justify-content-space-between align-items-center">
      <div>
        <div className={styles.cardSubTitle}>
          <p>Status</p>
        </div>
        <div className={styles.cardStatus}>
          <ProgramStatus status={status} />
        </div>
      </div>
    </div>
    {status === 1 && (
      <div className={styles.cardReason}>
        <p>Segera lakukan pembayaran sebelum kuota habis</p>
      </div>
    )}
  </div>
);

ProgramBody.propTypes = {
  id: PropTypes.number.isRequired,
  program: PropTypes.shape({
    alt_name: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  status: PropTypes.number.isRequired,
  goToDetailEnrollment: PropTypes.func.isRequired,
  batch: PropTypes.string.isRequired,
};

export default ProgramBody;
