import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.css";

const Testimoni = ({ imgSrc, name, description }) => (
  <Fragment>
    <img src={imgSrc} alt={name} className={styles.testimoniAvatar} />
    <h4>{name}</h4>
    <div className={styles.testimoniDesc} dangerouslySetInnerHTML={{__html: description}}/>
  </Fragment>
);

Testimoni.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default Testimoni;
