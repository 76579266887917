// Import React and ReactDOM
import React from "react";
import ReactDOM from "react-dom";

// Import Framework7
import Framework7 from "framework7/lite-bundle";

// Import Framework7-React Plugin
import Framework7React from "framework7-react";

// Import Framework7 Styles
import "framework7/framework7-bundle.css";

// Import sentry dependency
import * as Sentry from "@sentry/browser";

// Import Icons and App Custom Styles
import "../css/app.less";
import "../css/icons.css";
import "../css/font.css";
import "../css/body.css";
import "../css/custom.css";

// Import App Component
import App from "../components/app.jsx";

// Init F7 React Plugin
Framework7.use(Framework7React);

Sentry.init({
  dsn: "https://039eb23ca74d45cba82408cc241d636d@o1104028.ingest.sentry.io/6130650",
  environment: "development",
  release: 'pab-ui@1.0.0'
});

// Mount React App
ReactDOM.render(
  React.createElement(App),
  document.getElementById("app"),
);
