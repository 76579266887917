import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import {Page, Block, BlockTitle, f7 } from "framework7-react";

import {useAuth} from "contexts/auth";
import {DataService} from "services";

import Navbar from "components/navbar/";
import ActivityGrid from "./program-list";
import styles from "./styles.module.css";

const ProgramHistoryPage = ({f7router}) => {
  const {session} = useAuth();
  const {user = {}} = session || {};
  const {profile = {}} = user || {};
  const [programs, setPrograms] = useState([]);

  const getProgramHistory = async () => {
    try {
      const path = "/v1/user/enrollments";
      const response = await DataService.get(path);
      const {data = []} = response || {};
      if (data.length > 0) {
        setPrograms(data);
      }
    } catch (err) {
      // sentryWriteLog({
      //   title: 'Failed get enrollments history',
      //   result: err,
      // })
      f7.dialog.alert(err, "Oops!");
    }
  };

  const goToDetailEnrollment = (id) => () => {
    f7router.navigate(`/enrollment/${id}`, {force: true});
  };

  const goToPaymentPage = (id) => () => {
    f7router.navigate(`/payment/${id}`, {force: true});
  };

  useEffect(() => {
    getProgramHistory();
  }, []);

  return (
    <Page name="program-history">
      <Navbar back="/">Kembali</Navbar>
      <div className={styles.container}>
        <BlockTitle className={styles.title}>
          {`Riwayat Studi ${profile.nickname || ""}`}</BlockTitle>
        <Block className={styles.wrapper}>
          <ActivityGrid
            programs={programs}
            goToPaymentPage={goToPaymentPage}
            goToDetailEnrollment={goToDetailEnrollment}
          />
        </Block>
      </div>
    </Page>
  );
};

ProgramHistoryPage.defaultProps = {
  f7router: {}
};

ProgramHistoryPage.propTypes = {
  f7router: PropTypes.object,
};

export default ProgramHistoryPage;
