import React from "react";
import PropTypes from "prop-types";
import styles from "./navbar.module.css";
import { Icon, f7 } from "framework7-react";

export const NavbarExtended = ({
  children,
  back,
  classNameRoot,
  classNameLink,
  icon,
}) => (
  <div className={`${styles.navbarSection} ${classNameRoot}`}>
    <div
      onClick={() => f7.views.main.router.back(back, {force: true})}
      className={`${styles.navbarSectionLink} ${classNameLink}`}
    >
      {icon} {children}
    </div>
  </div>
);

NavbarExtended.defaultProps = {
  back: null,
  children: null,
  classNameRoot: "",
  classNameLink: "",
  icon: <Icon icon="icon-back"/>
};

NavbarExtended.propTypes = {
  back: PropTypes.string,
  children: PropTypes.node,
  classNameRoot: PropTypes.string,
  classNameLink: PropTypes.string,
  icon: PropTypes.node,
};

export default NavbarExtended;
