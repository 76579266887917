export const schemaProfileItem = [
  {
    type: "text",
    label: "Nama Panggilan",
    name: "nickname"
  },
  {
    type: "text",
    label: "Nama Lengkap",
    name: "fullname"
  },
  {
    type: "text",
    label: "Jenis Kelamin",
    name: "gender"
  },
  {
    type: "text",
    label: "Tempat Tanggal Lahir",
    name: "birth_place"
  },
  {
    type: "text",
    label: "Email",
    name: "email"
  },
  {
    type: "number",
    label: "Nomor Whatsapp",
    name: "whatsapp_number"
  },
  {
    type: "number",
    label: "Nomor Alternatif",
    name: "phone_number"
  },
  // {
  //   type: "number",
  //   label: "Nomor KTP",
  //   name: "national_id"
  // },
  {
    type: "text",
    label: "Golongan Darah",
    name: "blood_type"
  },
  {
    type: "number",
    label: "Nomor darurat / keluarga yang bisa dihubungi",
    name: "emergency_contact_number"
  },
  {
    type: "text",
    label: "Hubungan dengan nomor darurat / keluarga yang dimasukkan",
    name: "emergency_contact_relation"
  },
  {
    type: "text",
    label: "Pekerjaan",
    name: "field_of_work"
  },
  {
    type: "text",
    label: "Pendidikan",
    name: "education_grade"
  },
  {
    type: "text",
    label: "Jurusan",
    name: "education_subject"
  },
  {
    type: "text",
    label: "Domisili",
    name: "domisili"
  },
  {
    type: "textarea",
    label: "Alamat",
    name: "address"
  },
  {
    type: "text",
    label: "Batch Pertama yang diikuti",
    name: "batch"
  },
  {
    type: "text",
    label: "Program perkuliahan <strong>pertama</strong> yang diikuti",
    name: "first_time_program"
  },
  {
    type: "text",
    label: "Program perkuliahan yang pernah diikuti",
    name: "enrolled_programs",
  }
  // {
  //   type: "text",
  //   label: "Instagram",
  //   name: "instagram"
  // },
  // {
  //   type: "text",
  //   label: "Facebook",
  //   name: "facebook"
  // },
  // {
  //   type: "text",
  //   label: "Twitter",
  //   name: "twitter"
  // }
];

export const schemaProfileFormItem = [
  {
    type: "text",
    label: "Nama Panggilan",
    name: "nickname",
    required: true,
    validate: true
  },
  {
    type: "text",
    label: "Nama Lengkap",
    name: "fullname",
    required: true,
    validate: true
  },
  {
    type: "radio",
    label: "Jenis Kelamin",
    placeholder: "Please choose...",
    name: "gender",
    option_name: "gender",
    required: true,
    validate: true
  },
  {
    type: "text",
    label: "Tempat Lahir",
    name: "birth_place",
    required: true,
    validate: true
  },
  {
    type: "datepicker",
    label: "Tanggal Lahir",
    name: "birth_date",
    dateFormat: "dd MM yyyy",
    required: true,
    validate: true
  },
  {
    type: "email",
    label: "Email",
    name: "email",
    required: true,
    validate: true
  },
  {
    type: "tel",
    label: "Nomor Whatsapp",
    name: "whatsapp_number",
    disabled: true,
    required: true,
    validate: true
  },
  {
    type: "tel",
    label: "Nomor Alternatif",
    name: "phone_number",
    required: false,
    validate: true,
    maxlength: 14
  },
  // {
  //   type: "number",
  //   label: "Nomor KTP",
  //   name: "national_id",
  //   validate: true
  // },
  {
    type: "select",
    label: "Golongan Darah",
    placeholder: "Please choose...",
    name: "blood_type",
    option_name: "blood_type",
    required: false,
    validate: false
  },
  {
    type: "tel",
    label: "Nomor darurat / keluarga yang bisa dihubungi",
    name: "emergency_contact_number",
    required: true,
    validate: true
  },
  {
    type: "text",
    label: "Hubungan dengan nomor darurat / keluarga yang dimasukkan",
    name: "emergency_contact_relation",
    required: true,
    validate: true
  },
  {
    type: "text",
    label: "Pekerjaan",
    name: "field_of_work",
    option_name: "field_of_work",
    required: true,
    validate: true
  },
  {
    type: "select",
    label: "Pendidikan",
    name: "education_grade",
    option_name: "education_grade",
    required: true,
    validate: false
  },
  {
    type: "text",
    label: "Jurusan",
    name: "education_subject",
    option_name: "education_subject",
    required: true,
    validate: true
  },
  {
    type: "text",
    label: "Domisili",
    name: "districts",
    required: true,
    validate: true
  },
  {
    type: "textarea",
    label: "Alamat",
    name: "address",
    required: true,
    validate: true
  },
  {
    type: "radio",
    label: "Pernah mengikuti perkuliahan di RISKA sebelumnya? ",
    name: "have_enrolled_before",
    option_name: "have_enrolled_before",
    required: false,
    validate: false
  },
  // {
  //   type: "text",
  //   label: "Instagram",
  //   name: "instagram",
  //   required: false,
  //   validate: false
  // },
  // {
  //   type: "text",
  //   label: "Facebook",
  //   name: "facebook",
  //   required: false,
  //   validate: false
  // },
  // {
  //   type: "text",
  //   label: "Twitter",
  //   name: "twitter",
  //   required: false,
  //   validate: false
  // }
];

export const additionalFormProfileItem = [
  {
    type: "select",
    label: "Pada batch berapa <strong>pertama</strong> kali kamu masuk RISKA?",
    name: "batch",
    option_name: "previous_batch",
    required: false,
    validate: false
  },
  {
    type: "select",
    label: "Program perkuliahan <strong>pertama</strong> yang diikuti",
    name: "first_time_program",
    option_name: "departments",
    required: false,
    validate: false
  },
  {
    type: "checkbox",
    label: "Program perkuliahan yang pernah diikuti",
    name: "enrolled_programs",
    option_name: "departments",
    required: false,
    validate: false
  }
];
