import React from "react";
import {Card, CardContent} from "framework7-react";

import styles from "./styles.module.css";

const ProgramEmpty = () => (
  <div className={styles.root}>
    <Card>
      <CardContent className={styles.content}>
        <h1 className={styles.title}>
          Coming soon! Pendaftaran anggota baru RISKA akan segera dibuka
        </h1>
      </CardContent>
    </Card>
    <img
      className={styles.illustration}
      src="https://s3.elastis.id/riska/media/asset/img/afwan-illustration.png"
      alt="Afwan"
    />
  </div>
);

ProgramEmpty.propTypes = {};

export default ProgramEmpty;