import React from "react";
import {Button, Icon} from "framework7-react";
import PropTypes from "prop-types";

import PopupCropper from "./popup-cropper";

import styles from "./styles.module.css";

function UploadProofPayment({
  paymentFile,
  onUploadAvatar,
  onUpload,
  onChangeFile,
  popupOpened,
  onPopupClosed,
  fileUpload,
  cropper,
}) {
  return (
    <div className={styles.paymentProof}>
      <h3>Upload Bukti Pembayaran</h3>
      <div className={styles.formUpload} onClick={onUploadAvatar}>
        {!paymentFile ? (
          <>
            <Icon material="photo" />
            <h5>Upload Bukti Pembayaran</h5>
          </>
        ) : (
          <img
            src={paymentFile}
            alt="payment"
          />
        )}
      </div>
      <Button
        disabled={!paymentFile}
        className="margin-top"
        type="submit"
        fill
        onClick={onUpload}
      >
        Konfirmasi Pembayaran
      </Button>

      <PopupCropper
        popupOpened={popupOpened}
        onPopupClosed={onPopupClosed}
        fileUpload={fileUpload}
        cropper={cropper}
      />

      <input
        className={styles.inputHide}
        type="file"
        id="paymentFile-file"
        accept="image/*"
        onChange={onChangeFile}
      />
    </div>
  );
}

UploadProofPayment.defaultProps = {
  paymentFile: "",
  fileUpload: ""
};

UploadProofPayment.propTypes = {
  paymentFile: PropTypes.string,
  onUploadAvatar: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  onChangeFile: PropTypes.func.isRequired,
  popupOpened: PropTypes.bool.isRequired,
  onPopupClosed: PropTypes.func.isRequired,
  fileUpload: PropTypes.string,
  cropper: PropTypes.object.isRequired,
};

export default UploadProofPayment;

