import React, { useState, useEffect } from "react";
import {Page, Block, BlockTitle, Fab, f7} from "framework7-react";
import ReactGA from "react-ga";

import { useAuth } from "contexts/auth";
import { DataService } from "services";
//import sentryWriteLog from '../../utils/sentry'

import Header from "./header";
import ProgramCarousel from "./program-carousel";
import BenefitCarousel from "./benefit-carousel";
import TestimoniSection from "./testimoni";
import ActivityGrid from "./activities-grid";
import ProgramEmpty from "./program-empty";

const HomePage = () => {
  const { session } = useAuth();
  const [isOpen, setIsOpen] = useState(true);
  const [programs, setPrograms] = useState([]);

  const refresh = (done) => {
    setTimeout(async () => {
      await getPrograms();
      done();
    }, 1000);
  };

  const getPrograms = async() => {
    try {
      const response = await DataService.get("/v1/pab/programs");
      setPrograms(response.data);
    } catch(e) {
      // sentryWriteLog({
      //   title: 'Failed get program datas',
      //   result: e,
      // })
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (programs.length > 0) {
      for (let key in programs) {
        ReactGA.event({
          category: "Program",
          action: "View Remaining Quota",
          label: programs[key].alt_name,
          value: programs[key].remaining_quota
        });
      }
    }
  }, [programs]);

  useEffect(() => {
    ReactGA.pageview("/");

    getPrograms();
  }, []);

  return (
    <Page name="home" ptr ptrMousewheel={true} onPtrRefresh={refresh}>
      <Header />

      {isOpen ? (
        <>
          <BlockTitle>Program Studi</BlockTitle>
          {programs.length > 0 && (
            <ProgramCarousel programs={programs} />
          )}
        </>
      ) : (
        <ProgramEmpty />
      )}

      <BlockTitle>Kenapa harus di RISKA?</BlockTitle>
      <BenefitCarousel />

      <BlockTitle>Kegiatan di RISKA</BlockTitle>
      <Block>
        <ActivityGrid />
      </Block>

      <BlockTitle>Apa kata orang tentang RISKA?</BlockTitle>
      <TestimoniSection />

      {session ? "" :
        <Fab
          position="center-bottom"
          slot="fixed"
          text="Daftar / Masuk"
          color="#29a95f"
          onClick={() => {
            ReactGA.event({
              category: "Modal",
              action: "Open Login"
            });
            f7.popup.open("#login-screen", true);
          }}
        />
      }
    </Page>
  );
};

export default HomePage;
