import React, {useState} from "react";
import PropTypes from "prop-types";
import {Block, BlockHeader, Button, List, ListInput, ListItem, LoginScreenTitle} from "framework7-react";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";

import {useSharedState} from "contexts/sharedState";

export const SignupForm = ({onBack, onSubmit}) => {

  const {phoneNumber} = useSharedState();

  const [fullName, setFullName] = useState("");
  const [nickName, setNickName] = useState("");
  const [gender, setGender] = useState("M");
  const [reference, setReference] = useState("Instagram RISKA");
  const [otherReference, setOtherReference] = useState("");
  const [birthDate, setBirthDate] = useState("");

  const submit = () => {
    let payload = {
      fullname: fullName,
      nickname: nickName,
      gender: gender,
      birth_date: birthDate,
      referral_source: reference === "Lainnya" ? otherReference : reference,
    }
    onSubmit(payload);
  }

  const isFormFilled = () => {
    if (fullName === "") return false;
    if (nickName === "") return false;
    if (birthDate === "") return false;
    if (reference === "") return false;
    if (reference === "Lainnya" && otherReference === "") return false;
    return true;
  };

  return (
    <>
      <LoginScreenTitle>Registrasi</LoginScreenTitle>
      <BlockHeader>Sepertinya nomor kamu belum terdaftar. Yuk lengkapi formulir berikut untuk
        mendaftar.</BlockHeader>
      <Block>
        <List inlineLabels>
          <ListInput value={phoneNumber} disabled>
            <FontAwesomeIcon
              icon={faWhatsapp}
              size="2x"
              color="#21884d"
              slot="media"
            />
          </ListInput>
        </List>
        <List noHairlinesMd>
          <ListInput
            outline
            floatingLabel
            label="Nama Lengkap"
            type="text"
            placeholder="Nama Lengkap"
            onInput={(e) => setFullName(e.target.value)}
            clearButton/>
          <ListInput
            outline
            floatingLabel
            label="Nama Panggilan"
            type="text"
            placeholder="Nama Panggilan"
            onInput={(e) => setNickName(e.target.value)}
            clearButton/>
          <ListInput
            outline
            floatingLabel
            label="Jenis Kelamin"
            type="select"
            defaultValue="Laki-laki"
            placeholder="Please choose..."
            onInput={(e) => setGender(e.target.value)}>
            <option value="M">Laki-laki</option>
            <option value="F">Perempuan</option>
          </ListInput>
          <ListInput
            outline
            floatingLabel
            label="Tanggal Lahir"
            type="date"
            max={moment().format("YYYY-MM-DD")}
            placeholder="Please choose..."
            onInput={(e) => setBirthDate(e.target.value)}/>
        </List>
        <p>Darimana anda mengetahui pendaftaran anggota baru RISKA?</p>
        <List noHairlinesMd>
          <ListInput
            outline
            floatingLabel
            label="Tahu RISKA dari mana?"
            type="select"
            defaultValue=""
            onInput={(e) => setReference(e.target.value) && setOtherReference("")}>
            <option value="Instagram RISKA">Instagram RISKA</option>
            <option value="Rekomendasi Teman">Rekomendasi Teman</option>
            <option value="Kajian Masjid Sunda Kelapa">Kajian Masjid Sunda Kelapa</option>
            <option value="Influencer">Influencer</option>
            <option value="Kegiatan Remaja Islam Sunda Kelapa (RISKA)">Kegiatan Remaja Islam Sunda Kelapa
              (RISKA)
            </option>
            <option value="Lainnya">Lainnya</option>
          </ListInput>
          {reference === "Lainnya" &&
            <ListInput
              outline
              type="text"
              placeholder="Sebutkan"
              onInput={(e) => setOtherReference(e.target.value)}
              clearButton/>
          }
        </List>
      </Block>
      <Block>
        <Button fill disabled={!isFormFilled()} onClick={() => submit()}>Lanjut</Button>
        <br/>
        <Button onClick={() => onBack()}>Kembali</Button>
      </Block>
    </>
  );
}

SignupForm.propTypes = {
  onBack: PropTypes.func,
  onSubmit: PropTypes.func,
}

export default SignupForm;
