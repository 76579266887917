import React from "react";
import {Page} from "framework7-react";

import styles from "./styles.module.css";

const maintenanceIllustration = 
  "https://s3.elastis.id/riska/media/asset/img/maintenance_illustration.png";

const Maintenance = () => (
  <Page name="maintenance">
    <div className={styles.root}>
      <img
        className={styles.illustration}
        src={maintenanceIllustration}
        alt="Maintenance"
      />
      <h1 className={styles.title}>Afwan</h1>
      <h5 className={styles.caption}>
        Website Penerimaan Anggota Baru Riska <br />
        sedang dalam perbaikan
      </h5>
    </div>
  </Page>
);

Maintenance.propTypes = {};

export default Maintenance;

