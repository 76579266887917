import React, {useEffect, useState, useRef} from "react";
import PropTypes from "prop-types";
import { Button, Row, Col, f7, PhotoBrowser, Sheet } from "framework7-react";

import {DataService} from "services";
import sentryWriteLog from '../../../utils/sentry'
import {useAuth} from "contexts/auth";

import GeneralHeader from "./generalHeader";
import SpecialHeader from "./specialHeader";
import styles from "./programDetailArticle.module.css";

export const Article = ({ program, programId, f7router }) => {
  if (!program) return null;

  const standalone = useRef(null);

  const {query = {}} = f7router.currentRoute || {};
  const {from = null} = query || {};
  const { session } = useAuth();

  const [sheetOpened, setSheetOpened] = useState(false);
  const [message, setMessage] = useState("");
  const [guest, setGuest] = useState(false);

  const messages = {
    success: "Proses pendaftaran berhasil",
  };

  const errorCodeMap = {
    301: "Anda sudah terdaftar diprogram lain",
    302: "Kuota program telah habis",
    303: "Program ini khusus untuk Akhwat",
    304: "Umur Kamu tidak memenuhi persyaratan",
    306: "Pendaftaran hanya untuk domisili Jabodetabek"
  };

  const isSpecialCase = () => {
    return program.remaining_male_quota != null || program.remaining_female_quota != null;
  };

  const enrollProgram = () => {
    f7.dialog.preloader();

    DataService.post("/v1/user/enrollments", {
      program_id: program.id
    }).then((response) => {
      setMessage(messages.success);
      setSheetOpened(true);
      setTimeout(() => {
        setSheetOpened(false);
        f7.dialog.close();
      }, 500);

      if (response.enrollment_id) {
        const pathURL = "/enrollment/" + response.enrollment_id + "?guide";
        f7.views.main.router.navigate(pathURL, {force: true});
      }
    }).catch((err) => {
      f7.dialog.close();

      if (err.code === 305) {
        const path = `/profile/edit?from=program-detail&programId=${programId}`;
        f7.dialog.create({
          text: "Kamu belum melengkapi data profil",
          buttons: [
            {
              text: "Lengkapi sekarang",
              onClick: () => {
                f7.views.main.router.navigate(path, {force: true});
              }
            },
          ],
          verticalButtons: true,
        }).open();
      } else {
        // sentryWriteLog({
        //   title: 'Failed post enroll program',
        //   result: e,
        // })

        let msg = "Ada kendala teknis. Silahkan coba lagi atau hubungi CP";
        if (err.code != null && typeof errorCodeMap[err.code] !== "undefined") {
          msg = errorCodeMap[err.code];
        }
        f7.dialog.alert(msg, "Oops!", () => {
          if (err.code === 301) f7router.navigate("/enrollment", {force: true });
        });
        setMessage(errorCodeMap[err.code]);
        setTimeout(() => setSheetOpened(false), 1000);
      }
    });
  };

  const enrollConfirm = () => {
    if (!session) {
      setGuest(true);
      f7.popup.open("#login-screen", true);
    }
    else {
      f7.dialog.create({
        title: "Konfirmasi Pendaftaran",
        text: `Apakah kamu yakin ingin mendaftar program <b>${program.name}</b>?`,
        buttons: [
          {
            text: "Batal",
            onClick: () => f7.dialog.close(),
            cssClass: "cancel-button"
          },
          {
            text: "Lanjutkan",
            onClick: () => enrollProgram(),
            cssClass: "ok-button"
          },
        ],
        verticalButtons: false,
      }).open();
    }
    
  };

  const sendWhatsapp = (number) => {
    if (number.substr(0, 1) === "0") {
      number = "62" + number.substr(1);
    }
    window.open(`https://wa.me/${number}`);
  };

  useEffect(() => {
    if (session && guest) {
      setMessage("Hi, " + session.user.profile.nickname + "!");
      setSheetOpened(true);
      setTimeout(() => setSheetOpened(false), 2000);
      enrollConfirm();
    }
  }, [session]);

  useEffect(() => {
    if (from === "profile") {
      enrollConfirm();
    }
  }, []);

  return (
    <>
      <PhotoBrowser 
        theme="dark" 
        ref={standalone}
        photos={program.banner_activities.map((img)=> img)} 
      />
      <div className={styles.studyArticle}>
        <div className={styles.studyArticleMain}>
          {isSpecialCase() ? (
            <SpecialHeader
              title={program.name}
              subtitle={program.alt_name}
              male_quota={program.remaining_male_quota}
              female_quota={program.remaining_female_quota}
            />
          ) : (
            <GeneralHeader
              title={program.name}
              subtitle={program.alt_name}
              quota={program.remaining_quota}
            />
          )}
          <div
            className={styles.studyArticleMainText}
            dangerouslySetInnerHTML={{__html: program.description}}
          />
          <Row>
            {program.banner_activities.map((data, index) => (
              <Col
                key={index}
                width="50"
                className={styles.studyArticleMainImg}
                style={{ backgroundImage: `url(${data})` }}
                onClick={() => standalone.current.open(index)}
              />
            ))}
          </Row>
          <div className={styles.studyArticleMainFee}>
            <h3>Biaya</h3>
            {program.fee === program.first_payment ? (
              <p>
                Biaya keseluruhan mengikuti perkuliahan hingga lulus adalah:
              </p>
            ) : (
              <p>
                Biaya keseluruhan mengikuti perkuliahan hingga lulus adalah
                <b> Rp. {program.fee.toLocaleString("id-ID", {maximumFractionDigits: 0})} </b>
                dapat diangsur sebanyak dua kali, <br/>
                dengan nominal pembayaran pertama sebesar
              </p>
            )}
            <Row className={styles.studyArticleMainFeeAmount}>
              <Col width="100">
                <p>Rp. {program.first_payment.toLocaleString("id-ID", {maximumFractionDigits: 0})}</p>
              </Col>
            </Row>
          </div>
          <div>
            <h3>Contact Person</h3>
            <Row>
              {program.contact_person.map((data, index) => (
                data.name && data.number ? (
                  <Col
                    key={index}
                    width="50"
                    className={styles.studyArticleMainContactNumber}
                    onClick={() => sendWhatsapp(data.number)}
                  >
                    {data.name}
                    <p>{data.number}</p>
                  </Col>
                ) : <></>
              ))}
            </Row>
          </div>
          <div className={styles.studyArticleMainRegis}>
            <Button round={true} fill large onClick={() => enrollConfirm()}>
              Daftar
            </Button>
          </div>
        </div>
      </div>
      {/* toast modal */}
      <Sheet
        className={styles.studyArticleMainRegisSheetSection}
        opened={sheetOpened}
        onSheetClosed={() => setSheetOpened(false)}
        position={top}
      >
        <div className={styles.studyArticleMainRegisSheet}>
          {message}
        </div>
      </Sheet>
    </>
  );
};

Article.propTypes = {
  program: PropTypes.object,
  programId: PropTypes.string.isRequired,
  f7router: PropTypes.object.isRequired,
};

export default Article;
