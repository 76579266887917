import React from "react";
import {Icon} from "framework7-react";
import PropTypes from "prop-types";

import styles from "./styles.module.css";

const Alert = ({message}) => (
  <div className={`${styles.alert} ${styles.alertWarning}`}>
    <Icon material="query_builder" />
    <div className="margin-left">{message}</div>
  </div>
);

Alert.propTypes = {
  message: PropTypes.string.isRequired,
};

export default Alert;

