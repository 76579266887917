import React, { useState, useEffect, useCallback} from "react";
import {Page, Icon, Sheet, f7} from "framework7-react";
import { DataService } from "services";
import sentryWriteLog from '../../utils/sentry'
import PropTypes from "prop-types";

import {toBase64, getCroppedImg} from "utils/file";
import Navbar from "components/navbar";
import Alert from "components/alert";
import {LoginPopup} from "components/login";
import {useAuth} from "contexts/auth";

import Info from "./info";
import Attention from "./attention";
import UploadPaymentProof from "./proof-payment";
import styles from "./styles.module.css";

const PaymentPage = ({invoiceId, f7router}) => {
  const { session } = useAuth();

  const [invoice, setInvoice] = useState(null);

  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [popupOpened, setPopupOpened] = useState(false);
  const [fileUpload, setFileUpload] = useState("");
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [paymentFile, setPaymentFile] = useState("");
  const [sheetOpened, setSheetOpened] = useState(false);
  const [message, setMessage] = useState("");

  const messages = {
    success: "Upload Bukti Transfer Berhasil",
  };

  const onUploadAvatar = () => {
    document.getElementById("paymentFile-file").click();
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onRemoveAvatar = () => {
    const defaultAvatar = `https://ui-avatars.com/api/?name=${profile.fullname || ""}`;
    setPaymentFile(defaultAvatar);
  };

  const onChangeFile = async (e) => {
    const file = e.target.files[0];
    const result = await toBase64(file).catch(e => Error(e));
    if(result instanceof Error) {
      console.log("Error: ", result.message);
      return;
    }
    setFileUpload(result);
    setPopupOpened(true);
  };

  const onUpload = async () => {
    f7.dialog.preloader();
    try {
      const result = await DataService.post("/v1/invoices/"+ invoice.id +"/payments", {"payment_proof": paymentFile});

      if (result) {
        f7.dialog.close();
        f7router.back("/enrollment/" + invoice.user_program_id, {force: true});
        setSheetOpened(true);
        setMessage(messages.success);
        setTimeout(() => setSheetOpened(false), 1000);
      }
    } catch(e) {
      // sentryWriteLog({
      //   title: 'Failed post upload payment',
      //   result: e,
      // })
      f7.dialog.close();
      setMessage(e.message);
      setSheetOpened(true);
      setTimeout(() => setSheetOpened(false), 2000);
    }
  };

  const onSaveImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        fileUpload,
        croppedAreaPixels,
      );

      setPaymentFile(croppedImage);
      setFileUpload("");
      setCrop({ x: 0, y: 0 });
      setPopupOpened(false);
      setZoom(1);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  const getInvoices = async() => {
    try {
      const response = await DataService.get("/v1/invoices/"+ invoiceId);
      setInvoice(response);
      f7.preloader.hide();
    } catch(e) {
      // sentryWriteLog({
      //   title: 'Failed get invoice data',
      //   result: e,
      // })
      console.log(e);
      f7router.back("/", {force: true });
    }
  };

  useEffect(() => {
    if (!invoice) return;
    if (invoice.has_payment) f7router.back("/enrollment/" + invoice.user_program_id, {force: true });
  }, [invoice]);

  useEffect(() => {
    if (session === null) return;
    f7.preloader.show();
    if (session === "" || session === false) {
      setTimeout(() => {
        f7.preloader.hide();
        f7.popup.open("#login-from-payment", true);
      }, 500);
    }
    else setTimeout(() => getInvoices(), 500);
  }, [session]);

  return (
    <Page name="payment" style={{backgroundColor: "#f3f3f3"}}>
      <Navbar
        classNameRoot={styles.navbar}
        classNameLink={styles.navbarLink}
        icon={<Icon material="arrow_back" />}
      >
        <span className={styles.navbarTitle}>Konfirmasi Pembayaran</span>
      </Navbar>
      <div className={styles.wrapper}>
        <Alert message="Pendaftaran telah dilakukan, mohon untuk segera melakukan pembayaran" />
        <Info {...invoice}/>
        <Attention />
        <UploadPaymentProof
          paymentFile={paymentFile}
          fileUpload={fileUpload}
          popupOpened={popupOpened}
          onUploadAvatar={onUploadAvatar}
          onRemoveAvatar={onRemoveAvatar}
          onChangeFile={onChangeFile}
          onPopupClosed={() => setPopupOpened(false)}
          onUpload={onUpload}
          cropper={{
            crop,
            setCrop,
            zoom,
            setZoom,
            onSaveImage,
            onCropComplete
          }}
        />
        <Sheet
          className={styles.studyArticleMainRegisSheetSection}
          opened={sheetOpened}
          onSheetClosed={() => {
            setSheetOpened(false);
          }}
        >
          <div className={styles.studyArticleMainRegisSheet}>
            {message}
          </div>
        </Sheet>
      </div>
      <LoginPopup id="login-from-payment" closeable={false} />
    </Page>
  );
};

PaymentPage.propTypes = {
  invoiceId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired
};

export default PaymentPage;
