import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Page, f7, Icon} from "framework7-react";

import Navbar from "components/navbar";
import {DataService} from "services";
import sentryWriteLog from '../../utils/sentry'

import ProgramData from "./program-data";
import PaymentHistory from "./payment-history";
import Invoices from "./invoices";
import styles from "./styles.module.css";
import Tour from "reactour";

const ProgramEnrollmentPage = ({ programId }) => {
  const [enrollment, setEnrollment] = useState(null);

  const getEnrollment = async () => {
    try {
      const path = `/v1/user/enrollments/${programId}`;
      const response = await DataService.get(path);
      if (response) {
        setEnrollment(response);
      }
    } catch (err) {
      // sentryWriteLog({
      //   title: 'Failed get enrollments program',
      //   result: err,
      // })
      f7.dialog.alert(err, "Oops!");
    }
  };

  useEffect(() => {
    getEnrollment();
  }, []);

  return (
    <Page name="program-enrollment" style={{backgroundColor: "#f3f3f3"}}>
      <Navbar
        classNameRoot={styles.navbar}
        classNameLink={styles.navbarLink}
        icon={<Icon material="arrow_back" />}
        back="/enrollment"
      >
        <span className={styles.navbarTitle}>Detail Program</span>
      </Navbar>
      {enrollment && (
        <>
          <div className={styles.wrapper}>
            <ProgramData {...enrollment.program} />
            <Invoices data={enrollment.invoices} />
            <PaymentHistory
              program={enrollment.program}
              payments={enrollment.payments}
            />
          </div>
        </>
      )}
    </Page>
  );
};

ProgramEnrollmentPage.propTypes = {
  programId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])
};

export default ProgramEnrollmentPage;
