import React from "react";
import PropTypes from "prop-types";
import { Block, List, Button, Icon, Popover, ListItem } from "framework7-react";

import PopupCropper from "./popup-cropper";
import styles from "./profile.module.css";

const Header = ({
  data,
  avatar,
  onUploadAvatar,
  onRemoveAvatar,
  onChangeFile,
  popupOpened,
  onPopupClosed,
  fileUpload,
  cropper
}) => {
  return (
    <div className={styles.profileHeader}>
      <Block id="blockHeader">
        <h1>
          <span style={{ color: "#29a95f" }}>Profile</span>
        </h1>
      </Block>
  
      <Block className="text-align-center">
        <img
          className={styles.profileAvatar}
          src={avatar}
          alt={data.fullname}
        />
        <Button
          fill
          popoverOpen=".popover-menu"
          className={styles.profileSetting}
        >
          <Icon f7="ellipsis"></Icon>
        </Button>
  
        <Popover className="popover-menu">
          <List>
            <ListItem link="#" popoverClose title="Upload Foto" onClick={onUploadAvatar} />
            <ListItem link="#" popoverClose title="Remove Foto" onClick={onRemoveAvatar} />
          </List>
        </Popover>
        
        <PopupCropper
          popupOpened={popupOpened}
          onPopupClosed={onPopupClosed}
          fileUpload={fileUpload}
          cropper={cropper}
        />
      </Block>

      <input type="file" id="avatar-file" accept="image/*" onChange={onChangeFile} />
    </div>
  );
};

Header.defaultProps = {
  data: {},
  fileUpload: "",
  avatar: "https://ui-avatars.com/api/?name="
};

Header.propTypes = {
  data: PropTypes.object,
  fileUpload: PropTypes.string,
  avatar: PropTypes.string,
  onUploadAvatar: PropTypes.func.isRequired,
  onRemoveAvatar: PropTypes.func.isRequired,
  onChangeFile: PropTypes.func.isRequired,
  popupOpened: PropTypes.bool.isRequired,
  onPopupClosed: PropTypes.func.isRequired,
  cropper: PropTypes.object.isRequired,
};

export default Header;
