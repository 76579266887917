import React from "react";
import {Button, f7, Icon} from "framework7-react";

import {inAmount, inRupiah} from "utils/currency";

import styles from "./styles.module.css";

const Info = ({invoice_code, unique_code, amount}) => {
  const bank_account = {
    acc_number: '7299920008',
    holder_name: 'Remaja Islam Sunda Kelapa',
  }

  const showToast = (message) => {
    f7.toast.create({
      text: message,
      closeTimeout: 2000,
    }).open();
  }

  return (
    <div className={styles.info}>
      <div className="text-align-center margin-bottom">
        Silahkan melakukan pembayaran melalui transfer ke rekening berikut
      </div>
      <img src="https://s3.elastis.id/riska/media/asset/img/bank_bsi.jpeg" alt="BSI Syariah" className={styles.bankLogo} />
      <h3 className="text-align-center pointer">
        {bank_account.acc_number}
      </h3>
      <h5 className="text-align-center text-color-gray no-margin-top">
        {bank_account.holder_name}
      </h5>
      <hr className={styles.divider} />
      <div className="display-flex justify-content-space-between margin-top">
        <h5 className="text-color-gray no-margin">Kode Invoice</h5>
        <h4 className="no-margin">{invoice_code}</h4>
      </div>
      <div className="display-flex justify-content-space-between margin-top">
        <h5 className="text-color-gray no-margin">
          Jumlah yang harus ditransfer
        </h5>
        {invoice_code &&
        // <CopyToClipboard text={amount + unique_code} onCopy={() => showToast("Nominal pembayaran berhasil disalin")}>
        //   <h4 className="no-margin pointer">
        //     {inRupiah(amount/1000)}<b className={styles.uniq}>{unique_code.toString().padStart(3, "0")}</b>
        //   </h4>
        // </CopyToClipboard>
        <h4 className="no-margin pointer">
          {inRupiah(amount/1000)}<b className={styles.uniq}>{unique_code.toString().padStart(3, "0")}</b>
        </h4>
        }
      </div>
      <hr className={styles.dividerBottom} />
      {invoice_code &&
      <div className={styles.infoDetailAmount}>
        <h5>Rincian Jumlah</h5>
        <div className={styles.infoDetailAmountList}>
          <h6>Biaya Pembayaran</h6>
          <span>{inAmount(amount)}</span>
        </div>
        <div className={styles.infoDetailAmountList}>
          <h6>Kode Unik</h6>
          <span>{unique_code.toString().padStart(3, "0")}</span>
        </div>
      </div>
      }
    </div>
  );
}

export default Info;
