import React from "react";
import PropTypes from "prop-types";
import styles from "./programDetailHeader.module.css";

export const Header = ({program}) => {
  if (!program) return null;

  const getBackgroundUrl = (img) => {
    let source = img; // TODO replace with default placeholder
    if (img !== null) source = img;
    return `url(${img})`
  }

  return (
    <div className={styles.studyHeaderGradient}>
      <div className={styles.studyHeader} style={{backgroundImage: getBackgroundUrl(program.banner_detail || null)}}/>
    </div>
  );
};

Header.propTypes = {
  program: PropTypes.object
};

export default Header;
