import React, { useRef } from "react";
import styles from "./activities-grid.module.css";
import { Row, Col, PhotoBrowser } from "framework7-react";

export const ActivityGrid = () => {
  const images = [
    {
      id: 1,
      img:
        "https://s3.elastis.id/riska/media/asset/img/pab_activity_1.jpg",
    },
    {
      id: 2,
      img:
        "https://s3.elastis.id/riska/media/asset/img/pab_activity_2.jpg",
    },
    {
      id: 3,
      img:
        "https://s3.elastis.id/riska/media/asset/img/pab_activity_3.jpg",
    },
    {
      id: 4,
      img:
      "https://s3.elastis.id/riska/media/asset/img/pab_activity_4.jpg",
    },
    {
      id: 5,
      img:
        "https://s3.elastis.id/riska/media/asset/img/pab_activity_5.jpg",
    },
    {
      id: 6,
      img:
        "https://s3.elastis.id/riska/media/asset/img/pab_activity_6.jpg",
    },
    {
      id: 7,
      img:
        "https://s3.elastis.id/riska/media/asset/img/pab_activity_7.jpg",
    },
    {
      id: 8,
      img:
        "https://s3.elastis.id/riska/media/asset/img/pab_activity_8.jpg",
    }
  ];

  const standalone = useRef(null);

  return (
    <Row>
      <PhotoBrowser 
        photos={images.map((data)=> data.img)} 
        theme="dark" 
        ref={standalone}
      />
      {images.map((data) => (
        <Col
          key={data.id}
          large="25"
          width="50"
          className={styles.imageGrid}
          onClick={() => standalone.current.open(data.id -1)}
          style={{ backgroundImage: `url(${data.img})` }}
        />
      ))}
    </Row>
  );
};

export default ActivityGrid;
