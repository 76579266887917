import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Block, BlockHeader, Button, f7, LoginScreenTitle} from "framework7-react";
import ReactGA from "react-ga";
import ReactCodeInput from "react-code-input";

import {useSharedState} from "contexts/sharedState";
import {AuthService} from "services";
//import sentryWriteLog from '../../utils/sentry'

import ResendOtp from "./resend-otp";
import styles from "./login.module.css";

export const OtpForm = ({onBack, onVerified}) => {

  const otpLength = 4;

  const {phoneNumber, personalData} = useSharedState();

  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");

  const sendOtp = () => {
    AuthService.requestOtp(phoneNumber, personalData).then((response) => {
      if (response.error === "user is not registered.") onOtpFail();
      else {
        ReactGA.event({
          category: 'User',
          action: 'OPT sent',
          label: phoneNumber
        });
      }
    }).catch(e => {
      // sentryWriteLog({
      //   title: 'Failed request OTP',
      //   result: e,
      // })
    });
  }

  const verifyOtp = () => {
    f7.preloader.show();
    AuthService.verifyOtp(phoneNumber, otp, personalData).then(result => {
      f7.preloader.hide();
      if (result.access_token) {
        ReactGA.event({
          category: 'User',
          action: 'Valid OTP',
          label: phoneNumber
        });
        onVerified(result);
      }
      else {
        ReactGA.event({
          category: 'User',
          action: 'Invalid OTP',
          label: phoneNumber
        });
        setError("Kode yang kamu masukkan salah!");
        setOtp("····");
      }
    }).catch(e => {
      // sentryWriteLog({
      //   title: 'Failed post verify OTP',
      //   result: e,
      // })
    });
  };

  const isOtpFilled = () => {
    return otp.length === otpLength;
  };

  const onCompleteVerify = (value) => {
    console.log(value);
    f7.preloader.show();
    AuthService.verifyOtp(phoneNumber, value, personalData).then(result => {
      f7.preloader.hide();
      if (result.access_token) {
        ReactGA.event({
          category: 'User',
          action: 'Valid OTP',
          label: phoneNumber
        });
        onVerified(result);
      }
      else {
        ReactGA.event({
          category: 'User',
          action: 'Invalid OTP',
          label: phoneNumber
        });
        setError("Kode yang kamu masukkan salah!");
      }
    });
  }

  useEffect(() => {
    if (isOtpFilled()) verifyOtp();
  }, [otp]);

  return (
    <>
      <LoginScreenTitle>Verifikasi Whatsapp</LoginScreenTitle>
      <BlockHeader>
        4 digit kode verifikasi telah dikirimkan ke nomor whatsapp kamu <b>({phoneNumber})</b>
      </BlockHeader>
      <Block className={styles.center}>
        {/*<ReactInputVerificationCode onChange={setOtp} value={otp}/>*/}
        <ReactCodeInput autoFocus={true} type="number" fields={otpLength} onChange={setOtp}/>
        {error === "" ? "" :
          <Block>
            <p className={styles.otpErrorMessage}>{error}</p>
          </Block>
        }
      </Block>
      <Block>
        <Button fill disabled={!isOtpFilled()} onClick={() => verifyOtp()}>Lanjut</Button>
        <br />
        <Button onClick={() => onBack()}>Kembali</Button>
      </Block>
      <ResendOtp onResend={() => sendOtp()} />
    </>
  );
}

OtpForm.propTypes = {
  onBack: PropTypes.func,
  onVerified: PropTypes.func,
}

export default OtpForm;
