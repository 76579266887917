import React from "react";
import PropTypes from "prop-types";
import { Block, Button, Icon, Page, Popup, Navbar, NavRight, Link, Range } from "framework7-react";
import Cropper from "react-easy-crop";

import styles from "../profile/profile.module.css";

const PopupCropper = ({
  popupOpened,
  onPopupClosed,
  fileUpload,
  cropper
}) => {
  return (
    <Popup
      opened={popupOpened}
      onPopupClosed={onPopupClosed}
      className={styles.popupCropper}
    >
      <Page>
        <Navbar title="Drag the image to adjust">
          <NavRight>
            <Link popupClose>Cancel</Link>
          </NavRight>
        </Navbar>
        <Block className={styles.cropWrapper}>
          <div className={styles.cropContainer}>
            <Cropper
              image={fileUpload}
              crop={cropper.crop}
              zoom={cropper.zoom}
              aspect={9 / 16}
              showGrid={false}
              onCropChange={cropper.setCrop}
              onZoomChange={cropper.setZoom}
              onCropComplete={cropper.onCropComplete}
            />
          </div>
          <div className={styles.cropControls}>
            <Range
              min={1}
              max={3}
              step={0.1}
              value={cropper.zoom}
              onRangeChange={(values) => cropper.setZoom(values)}
            />
          </div>
          <Button fill id="save-image" onClick={cropper.onSaveImage}>
            <Icon f7="checkmark_alt" size="44px"></Icon>
          </Button>
        </Block>
      </Page>
    </Popup>
  );
};

PopupCropper.defaultProps = {
  fileUpload: "",
};

PopupCropper.propTypes = {
  fileUpload: PropTypes.string,
  popupOpened: PropTypes.bool.isRequired,
  onPopupClosed: PropTypes.func.isRequired,
  cropper: PropTypes.object.isRequired,
};


export default PopupCropper;
