import React, {useState,useEffect} from "react";
import PropTypes from "prop-types";
import { Page } from "framework7-react";
import ReactGA from "react-ga";

import Navbar from "components/navbar";
import {DataService} from "services";
import sentryWriteLog from '../../utils/sentry'

import Header from "./header";
import Article from "./article";

const ProgramDetailPage = ({ programId, f7router }) => {
  const [program, setProgram] = useState(null);

  const getProgram = async () => {
    try {
      const response = await DataService.get("/v1/pab/programs/" + programId)
      setProgram(response)
    } catch(e) {
      // sentryWriteLog({
      //   title: 'Failed get program detail data',
      //   result: e,
      // })
    }
  };

  useEffect(() => {
    if (program == null) return;
    ReactGA.event({
      category: "Program",
      action: "View Remaining Quota",
      label: program.alt_name,
      value: program.remaining_quota
    });
  });

  useEffect(() => {
    ReactGA.pageview("/program-detail/" + programId);
    getProgram();
  }, []);

  return (
    <Page name="program-detail">
      <Navbar back="/">Kembali</Navbar>
      <Header program={program} />
      <Article f7router={f7router} programId={programId} program={program} />
    </Page>
  );
};

ProgramDetailPage.propTypes = {
  programId: PropTypes.string.isRequired,
  f7router: PropTypes.object.isRequired,
};

export default ProgramDetailPage;
