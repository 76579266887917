import React from "react";
import PropTypes from "prop-types";

import {dateFormat} from "utils/date";
import {inRupiah} from "utils/currency";

import styles from "./styles.module.css";

const PaymentHistory = ({ program, payments }) => {
  const { fee, unpaid } = program;
  return (
    <div className={styles.card}>
      <h3>Riwayat Pembayaran</h3>
      <div className="display-flex justify-content-space-between align-items-center">
        <p className={styles.label}>Biaya Perkuliahan</p>
        <p className={styles.amount}>{inRupiah(fee)}</p>
      </div>

      <hr className={styles.divider} />

      {payments.length > 0 ? payments.map((item, key) => (
        <div key={key} className="display-flex justify-content-space-between align-items-center">
          <p className={`${styles.label} display-flex align-items-center`}>
            <div className={styles.datePayments}>{dateFormat({date: item.payment_date, formatDate: "dd/MM"})}</div>
            <span className="margin-left">{item.description}</span>
          </p>
          <p className={`${styles.amount} ${styles.amountHistory}`}>
            {inRupiah(item.amount)}
          </p>
        </div>
      )) : (
        <div className={styles.emptyState}>
          Belum Ada Riwayat Pembayaran
        </div>
      )}

      {payments.length > 0 &&
      <>
        <hr className={styles.divider} />

        <div className="display-flex justify-content-space-between align-items-center">
          <p className={styles.label}>Sisa Pembayaran</p>
          <p className={styles.amount}>{inRupiah(unpaid)}</p>
        </div>
      </>
      }
    </div>
  );
}

PaymentHistory.propTypes = {
  program: PropTypes.shape({
    fee: PropTypes.number,
    unpaid: PropTypes.number,
  }).isRequired,
  payments: PropTypes.array.isRequired,
};

export default PaymentHistory;
