import React from "react";
import {Card, CardContent, CardHeader} from "framework7-react";
import Slider from "react-slick";

import styles from "./benefit.module.css";

export const BenefitCarousel = () => {
  const datas = [
    {
      id: 1,
      title: "Tempat menuntut ilmu yang anti bosen",
      body: "RISKA punya banyak program studi pendidikan yang sesuai dengan kebutuhan kamu loh! Materi yang kekinian, penyampaian yang anti bosen serta pengajar yang kece dan mumpuni di bidangnya akan membuat kamu betah belajar lama-lama.",
    },
    {
      id: 2,
      title: "Media menambah minat dan bakat",
      body: "RISKA punya banyak kelas minat dan bakat yang cocok untuk menambah skill kamu di bidang olahraga, literasi, tarik suara, desain, kreatifitas dan masih banyak lagi",
    },
    {
      id: 3,
      title: "Bertemu sahabat sevisi dan tujuan",
      body: "Dapet circle pertemanan gaul tapi masih mengingat Allah is another level of happiness. Di RISKA kamu engga akan sendiri, kamu bisa meluaskan pergaulan dan menemukan sahabat yang tidak hanya memikirkan dunia, tapi juga mendekatkan diri ke Allah",
    },
    {
      id: 4,
      title: "Feels like home",
      body: "RISKA adalah rumah. Kamu akan merasakan seperti memiliki keluarga yang hangat dan saling mendukung dalam hal-hal yang positif",
    },
    {
      id: 5,
      title: "Banyak event seru yang bervariasi",
      body: "Sering liat keseruan acara RISKA kan? Nah, kamu bisa banget nih menjadi bagian yang terlibat dalam event-event kajian, sosial dan berbagai event seru lainnya yang kekinian dan anak muda banget",
    },
    {
      id: 6,
      title: "Jalur menjadi pemuda bermanfaat",
      body: "Banyak kebaikan menanti kamu loh! Yakin engga mau mengisi waktu luangmu dengan kegiatan yang Insya Allah tidak hanya bermanfaat bagi diri kamu aja tapi juga orang lain? Yuk, segera ambil bagian menjadi pemuda muda, gaul dan berkarya",
    }
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerPadding: '32px',
    centerMode: true,
    autoplay: false,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <Slider {...settings}>
      {datas.map((data) => (
        <div key={data.id} className={styles.swiperSlide}>
          <Card className={styles.card}>
            <CardHeader>
              <b>{data.title}</b>
            </CardHeader>
            <CardContent>
              {data.body}
            </CardContent>
          </Card>
        </div>
      ))}
    </Slider>
  );
};

export default BenefitCarousel;
