import React from "react";
import PropTypes from "prop-types";
import {Col} from "framework7-react";

import ProgramBody from "./program-body";

export const ActivityGrid = ({programs, ...props}) => (
  <Col>
    {programs.map((data, key) => (
      <ProgramBody key={key} {...data} {...props} />
    ))}
  </Col>
);

ActivityGrid.propTypes = {
  programs: PropTypes.array.isRequired,
};

export default ActivityGrid;
