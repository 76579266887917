import fetch from "node-fetch";
import {AUTH_KEY, API_URL} from "constants/Environment";

class AuthService {

  static async requestOtp(whatsappId, data = null) {
    return await this.verifyOtp(whatsappId, null, data);
  }

  static async verifyOtp(whatsappId, otp, data) {
    const payload = {id: this.sanitizePhoneNumber(whatsappId), code: otp, data: data};
    const response = await fetch(API_URL + "/v1/auth/pab-login", {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payload)
    });
    const json = await response.json();
    // if (!response.ok) throw new Error(json.error);
    return json;
  }

  static async callbackAuth(result = null) {
    if (result == null) {
      const response = await fetch(API_URL + "/v1/auth/callback" + window.location.search, {});
      const json = await response.json();
      // if (!response.ok) throw new Error(json.error);
      result = json;
    }

    let date = new Date();
    date.setSeconds(date.getSeconds() + result.expires_in);
    return {
      user: await this.fetchIdentity(result.access_token),
      token: result.access_token,
      expired_at: date,
    };
  }

  static async fetchIdentity(access_token) {
    const response = await fetch(API_URL + "/v1/auth/me", {
      headers: { "Authorization": "Bearer " + access_token }
    });
    const json = await response.json();
    // if (!response.ok) throw new Error(json.error);

    return {
      profile: json.profile,
      permissions: json.permissions,
      roles: json.roles
    };
  }

  static getCurrentUser() {
    return JSON.parse(window.localStorage.getItem(AUTH_KEY));
  }

  static getAuthToken() {
    return this.getCurrentUser() ? this.getCurrentUser().token : null;
  }

  static sanitizePhoneNumber(number) {
    let id = number;
    id = id.replace("+", "");
    if (id[0] === "0") id = "62" + id.substr(1);
    return id;
  }
}

export default AuthService;
