import React from "react";
import PropTypes from "prop-types";
import {Button, Icon} from "framework7-react";

import styles from "./profile.module.css";

const Title = ({ data }) => (
  <div className={styles.profileTitle}>
    <h2>{data.nickname}</h2>
    <Button href="/profile/edit" fill>
      <Icon f7="pencil"></Icon>
    </Button>
  </div>
);

Title.defaultProps = {
  data: {}
};

Title.propTypes = {
  data: PropTypes.object,
};

export default Title;
