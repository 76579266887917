import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {Card, CardContent, Link} from "framework7-react";
import ReactGA from 'react-ga';

import styles from "./program.module.css";

export const CarouselBody = ({ data }) => {

  const getBackgroundUrl = (img) => {
    let source = img; // TODO replace with default placeholder
    if (img !== null) source = img;
    return `url(${img})`
  }

  useEffect(() => {
    ReactGA.event({
      category: 'Program',
      action: 'View Detail',
      label: data.alt_name
    });
  }, []);

  return (
    <Card className={styles.card} style={{ backgroundImage: getBackgroundUrl(data.banner_card) }}>
      <Link href={"/program-detail/" + data.id + "/"} className={styles.link}>
        <CardContent className={styles.cardContent}>
          <div className={styles.cardContentBottom}>
            <h1>{data.alt_name}</h1>
            <p>{data.name}</p>
            <div className={styles.cardContentBottomKuota}>
              <h3>Sisa Kuota</h3>
              <p>{data.remaining_quota}</p>
            </div>
          </div>
        </CardContent>
      </Link>
    </Card>
  );
}

CarouselBody.propTypes = {
  data: PropTypes.shape({
    banner_card: PropTypes.string,
    name: PropTypes.string,
    alt_name: PropTypes.string,
    remaining_quota: PropTypes.number,
  }).isRequired,
};

export default CarouselBody;
