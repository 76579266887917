export const program_study = [
  {
    key: 1,
    label: "BMAQ"
  },
  {
    key: 9,
    label: "Sister Club"
  },
  {
    key: 10,
    label: "SDIS"
  },
  {
    key: 11,
    label: "SDTNI"
  },
  {
    key: 12,
    label: "Cinematography"
  },
  {
    key: 13,
    label: "Volaris"
  }
];

export const gender = [
  {
    key: "M",
    label: "Ikhwan"
  },
  {
    key: "F",
    label: "Akhwat"
  }
];

export const have_enrolled_before = [
  {
    key: 1,
    label: "Ya"
  },
  {
    key: 2,
    label: "Tidak"
  }
];

export const educations = [
  {
    key: 1,
    label: "Sekolah Dasar"
  },
  {
    key: 2,
    label: "Sekolah Menengah Pertama"
  },
  {
    key: 3,
    label: "Sekolah Menengah Atas"
  },
  {
    key: 4,
    label: "Diploma"
  },
  {
    key: 5,
    label: "Sarjana"
  },
  {
    key: 6,
    label: "Pasca Sarjana"
  },
  {
    key: 7,
    label: "Doktor (PhD)"
  },
  {
    key: 8,
    label: "Lainnya"
  }
];

export const dummyPrograms = [
  {
    banner_card: "https://c0.wallpaperflare.com/preview/422/862/456/mosque-cami-prayer-istanbul.jpg",
    name: "SDTNI",
    alt_name: "Study Dasar Terpadu Nilai Islam",
    remaining_quota: 25
  },
  {
    banner_card: "https://www.thenationalnews.com/image/policy:1.860575:1559198783/AR_1205_Sharjah_Mosque-40.jpg?$p=87739a3&w=1136&$w=ec52ab9",
    name: "BMAQ",
    alt_name: "Belajar Mahir Al-Quran",
    remaining_quota: 40
  },
  {
    banner_card: "https://greekcitytimes.com/wp-content/uploads/2020/03/%CE%91%CE%B3%CE%AF%CE%B1-%CE%A3%CE%BF%CF%86%CE%AF%CE%B1.jpg",
    name: "SC",
    alt_name: "Sister Club",
    remaining_quota: 20
  }
];


export const dummyEnrollment = {
  program: {
    name: "Studi Dasar Terpadu",
    alt_name: "SDTNI",
    batch: "2021 Batch 1",
    status: 1,
    fee: 575000,
    unpaid: 225000
  },
  payments: [
    {
      id: "1",
      invoice_id: 1,
      payment_date: "2021-05-24",
      amount: 350000,
      description: "Pembayaran Pertama",
      approval_status: 3
    }
  ],
  invoices: [
    {
      id: 1,
      invoice_code: "PAB2101001",
      invoice_note: "Pembayaran Pertama",
      invoice_date: "2021-05-23",
      amount: 350000,
      paid: true
    },{
      id: 2,
      invoice_code: "PAB2101002",
      invoice_note: "Pembayaran Kedua",
      invoice_date: "2021-07-23",
      amount: 225000,
      paid: false
    }
  ]
};