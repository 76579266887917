import React from "react";

import styles from "./styles.module.css";

const Attention = () => (
  <div className={styles.attention}>
    <h3>Perhatian!</h3>
    <ul className={styles.list}>
      <li>
          Pastikan melakukan transfer <strong>hanya ke rekening yang tertera di atas</strong>
      </li>
      <li>Pastikan <strong>jumlah transfer sesuai</strong> dengan nominal total pembayaran</li>
      <li>Pastikan bukti transaksi yang diupload tercantum <strong>tanggal transaksi</strong></li>
    </ul>
  </div>
);

export default Attention;
