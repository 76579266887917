import React, { useEffect, useState } from "react";
import {App, f7, View} from "framework7-react";
import ReactGA from "react-ga";

import { AuthService } from "services";
import { AuthContext } from "contexts/auth";

import { AUTH_KEY, TRACKING_ID } from "constants/Environment";
import routes from "js/routes";

import {LoginPopup}  from "./login";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const AppMain = () => {

  ReactGA.initialize(TRACKING_ID);

  const f7params = {
    name: "PAB RISKA",
    theme: "ios",
    routes: routes,
  };

  const [session, setSession] = useState(null);

  useEffect(() => {
    ReactGA.event({
      category: "App",
      action: "Initialize App"
    });

    const sessionFromStorage = localStorage.getItem(AUTH_KEY);
    if (sessionFromStorage != null && sessionFromStorage !== "undefined") {
      let existingSession = JSON.parse(sessionFromStorage);
      AuthService.fetchIdentity(existingSession.token).then((response) => {
        if (typeof response.profile === "undefined") {
          setSession(false);
        }
        else {
          existingSession.user = response;
          setSession(existingSession);
        }
      });
    } else setSession(false);
  }, []);

  useEffect(() => {
    if (session == null) return;
    if (session === false) {
      ReactGA.set({ userId: null });
      localStorage.removeItem(AUTH_KEY);
    }
    else {
      ReactGA.set({ userId: session.user.profile.id });
      localStorage.setItem(AUTH_KEY, JSON.stringify(session));
    }
  }, [session]);

  return (
    <App { ...f7params }>
      <AuthContext.Provider value={{ session, setSession }}>
        {/* Your main view, should have "view-main" class */}
        <View
          main
          className="safe-areas"
          url="/"
          browserHistory
          browserHistorySeparator=""
        />
        {/* Global popup declaration */}
        <LoginPopup />
      </AuthContext.Provider>
    </App>
  );
};

export default AppMain;
