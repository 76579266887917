import HomePage from "../pages/home";
import ProfilePage from "../pages/profile";
import ProfileEditPage from "../pages/profile/edit/";
import ProgramDetailPage from "../pages/program-detail";
import ProgramHistoryPage from "../pages/program-history";
import ProgramEnrollmentPage from "../pages/program-enrollment";
import PaymentPage from "../pages/payment";
import MaintenancePage from "../pages/maintenance";
import NotFoundPage from "../pages/404";

const routes = [
  {
    path: "/",
    component: HomePage,
  },
  {
    path: "/profile",
    component: ProfilePage,
  },
  {
    path: "/profile/edit",
    component: ProfileEditPage,
  },
  {
    path: "/program-detail/:programId",
    component: ProgramDetailPage,
  },
  {
    path: "/enrollment",
    component: ProgramHistoryPage,
  },
  {
    path: "/enrollment/:programId",
    component: ProgramEnrollmentPage,
  },
  {
    path: "/payment/:invoiceId",
    component: PaymentPage,
  },
  {
    path: "/maintenance",
    component: MaintenancePage,
  },
  {
    path: "(.*)",
    component: NotFoundPage,
  },
];

export default routes;
