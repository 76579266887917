import React from "react";
import { Block, Col, Link, List, ListItem, Popover, Row, f7 } from "framework7-react";
import ReactGA from "react-ga";

import { useAuth } from "contexts/auth";

import styles from "./header.module.css";

const Header = () => {

  const { session, setSession } = useAuth();

  const logout = () => {
    ReactGA.event({
      category: 'User',
      action: 'Sign out',
      label: session.user.profile.whatsapp_number
    });
    f7.popover.close(".popover-menu", false);
    setSession(false);
  };

  return (
    <Block className={styles.header}>
      <Row>
        <Col width="75">
          <h1>
            <span className={styles.themeColor}>PAB</span> RISKA
          </h1>
        </Col>
        {session && (
          <Col width="25" className={styles.colUser}>
            <Link popoverOpen=".popover-menu">
              <img className={styles.avatar} src={session.user.profile.photo || "https://ui-avatars.com/api/?name=" + session.user.profile.fullname} alt={session.user.profile.fullname} />
            </Link>
          </Col>
        )}
        <Popover className="popover-menu">
          <List>
            <ListItem link="/profile" popoverClose title="Profil" />
            <ListItem link="/enrollment" popoverClose title="Riwayat Studi" />
            <ListItem onClick={() => logout()} popoverClose title="Keluar" />
          </List>
        </Popover>
      </Row>
    </Block>
  );
};

export default Header;
