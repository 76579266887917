import React from "react";
import { Row, Col } from "framework7-react";
import PropTypes from "prop-types";

import styles from "./programDetailArticle.module.css"

export const GeneralHeader = ({title, subtitle, quota}) => {
  return (
    <Row>
      <Col width="60">
        <h2>{title}</h2>
        <h5>{subtitle}</h5>
      </Col>
      <Col width="40" className={styles.studyArticleMainQuota}>
        <h4>Sisa Kuota</h4>
        <div className={styles.studyArticleMainQuotaNumber}>{quota}</div>
      </Col>
    </Row>
  );
};

GeneralHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  quota: PropTypes.number.isRequired
};

export default GeneralHeader;
