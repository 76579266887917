import fetch from "node-fetch";

import {API_URL} from "constants/Environment";

import AuthService from "./AuthService";

const queryString = (params) => {
  let result = Object.keys(params).map(key => key + "=" + params[key]).join("&");
  return result.length === 0 ? "" : "?" + result;
};

class DataService {
  static async get(path, queryParam = {}) {
    const response = await fetch(API_URL + path + queryString(queryParam), {
      headers: {
        "Authorization": "Bearer " + AuthService.getAuthToken()
      }
    });
    const json = await response.json();
    if (!response.ok) throw new Error(json.error);
    return json;
  }

  static async post(path, payload = {}) {
    const response = await fetch(API_URL + path, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + AuthService.getAuthToken()
      },
      body: JSON.stringify(payload)
    });
    const json = await response.json();
    if (!response.ok) throw json;
    return json;
  }

  static async upload(path, file, payload = {}) {
    var data = new FormData();
    data.append("file", file);
    for (let key in payload) data.append(key, payload[key]);

    const response = await fetch(API_URL + path, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Authorization": "Bearer " + AuthService.getAuthToken()
      },
      body: data,
    });
    const json = await response.json();
    if (!response.ok) throw new Error(json.error);
    return json;
  }

  static async put(path, payload = {}) {
    const response = await fetch(API_URL + path, {
      method: "PUT",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + AuthService.getAuthToken()
      },
      body: JSON.stringify(payload)
    });
    const json = await response.json();
    if (!response.ok) throw new Error(json.error);
    return json;
  }

  static async delete(path) {
    const response = await fetch(API_URL + path, {
      method: "DELETE",
      headers: {
        "Authorization": "Bearer " + AuthService.getAuthToken()
      }
    });
    const json = await response.json();
    if (!response.ok) throw new Error(json.error);
    return json;
  }
}

export default DataService;
