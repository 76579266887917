import React from "react";
import PropTypes from "prop-types";
import {f7} from "framework7-react";
import {List, ListInput, BlockTitle, Button, Radio, Checkbox} from "framework7-react";

import {schemaProfileFormItem, additionalFormProfileItem} from "data/profile";

import styles from "./styles.module.css";

const FormEditProfile = ({data, event, suggestions, errors, validInput}) => {
  const onSubmit = () => {
    const hasError = Object.keys(validInput).filter((row) => !validInput[row]);
    if (hasError.length > 0 || !data.district_id || !data.field_of_work) {
      f7.dialog.alert("Periksa kembali isian yang berwarna merah", "Update profil gagal");
    }
  };

  return (
    <form className={styles.formEdit} onSubmit={event.onSubmit}>
      <BlockTitle className={styles.blockTitle}>Edit Profil</BlockTitle>
      <List>
        {[
          ...schemaProfileFormItem,
          ...data.have_enrolled_before == 1 ? [...additionalFormProfileItem] : []
        ].map((row, key) => {
          const options = suggestions[row.option_name] || [];
          switch (row.type) {
          case "select":
            return (
              <ListInput
                key={key}
                label={(
                  <div
                    dangerouslySetInnerHTML={{__html: row.label}}
                  />
                )}
                type={row.type}
                name={row.name}
                value={data[row.name] || ""}
                onChange={event.onChange}
                placeholder={row.placeholder}
                required={row.required}
                validate={row.validate}
                onValidate={(isValid) => event.onValidateInput({name: row.name, isValid})}
              >
                {options.map((option, i) => (
                  <option key={i} value={option.key}>{option.label}</option>
                ))}
              </ListInput>
            );
          case "datepicker":
            return (
              <ListInput
                key={key}
                label={row.label}
                type={row.type}
                name={row.name}
                placeholder={row.label}
                required={row.required}
                validate={row.validate}
                onValidate={(isValid) => event.onValidateInput({name: row.name, isValid})}
                calendarParams={{
                  url: "/profile/edit",
                  locale: "id",
                  closeOnSelect: true,
                  maxDate: new Date(),
                  dateFormat: row.dateFormat,
                  value: [new Date(data.birth_date)],
                  on: {
                    change: ({value}) => {
                      event.onChangeDate({target: {
                        name: row.name,
                        value
                      }});
                    }
                  }
                }}
              />
            );
          case "radio":
            return (
              <li key={key}>
                <div className="item-content item-input item-input-with-value">
                  <div className="item-inner">
                    <div className="item-title item-label">{row.label}</div>
                    <div className="item-input-wrap">
                      <div className="display-flex justify-content-space-between margin-vertical padding-horizontal">
                        {options.map((option, key) => (
                          <div key={key}>
                            <Radio
                              name={row.name}
                              value={option.key}
                              checked={data[row.name] == option.key}
                              onChange={() => event.onChangeRadio({
                                name: row.name,
                                value: option.key
                              })}
                            />
                            <span className="margin-left">{option.label}</span>
                          </div>
                        ))}
                        {/* <div>
                          <Radio name={row.name} value={1} onChange={event.onChangeRadio} checked={data[row.name] == 1} />
                          <span className="margin-left">Ya</span>
                        </div>
                        <div>
                          <Radio name={row.name} value={2} onChange={event.onChangeRadio} checked={data[row.name] == 2} />
                          <span className="margin-left">Tidak</span>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            );
          case "checkbox":
            return (
              <li key={key}>
                <div className="item-content item-input item-input-with-value">
                  <div className="item-inner">
                    <div className="item-title item-label">{row.label}</div>
                    <div className="item-input-wrap">
                      <div className="display-flex flex-direction-column margin-bottom">
                        {options.map((option, key) => (
                          <div key={key} className="margin-vertical-half">
                            <Checkbox
                              name={row.name}
                              value={option.key}
                              onChange={event.onChangeCheckbox}
                              checked={data[row.name] && data[row.name].includes(option.key)}
                            />
                            <span className="margin-left">{option.label}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            );
          default:
            return (
              <ListInput
                key={key}
                label={row.label}
                type={row.type}
                name={row.name}
                placeholder={row.label}
                onInput={event.onChange}
                value={data[row.name] || ""}
                disabled={row.disabled || false}
                required={row.required}
                validate={row.validate}
                onValidate={(isValid) => event.onValidateInput({name: row.name, isValid})}
                maxlength={row.maxlength || 100}
                errorMessage={errors[row.name] || ""}
                errorMessageForce={errors[row.name] ? true : false}
                onFocus={() => event.onFocus({name: row.name})}
              />
            );
          }
        })}
      </List>
      <div className={styles.btnWrapper}>
        <Button type="submit" onClick={onSubmit} fill>Simpan</Button>
      </div>
    </form>
  );
};

FormEditProfile.defaultProps = {
  data: {},
  errors: {},
  suggestions: {},
  validInput: {},
  event: {
    onFocus: () => {},
    onChange: () => {},
    onSubmit: () => {},
    onChangeDate: () => {},
    onChangeFieldOfWork: () => {},
    onChangeDistrict: () => {},
    onValidateInput: () => {},
    onChangeRadio: () => {},
    onChangeCheckbox: () => {},
  }
};

FormEditProfile.propTypes = {
  data: PropTypes.object,
  suggestions: PropTypes.object,
  errors: PropTypes.object,
  validInput: PropTypes.object,
  event: PropTypes.shape({
    onFocus: PropTypes.func,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
    onChangeFieldOfWork: PropTypes.func,
    onChangeDate: PropTypes.func,
    onChangeDistrict: PropTypes.func,
    onValidateInput: PropTypes.func,
    onChangeRadio: PropTypes.func,
    onChangeCheckbox: PropTypes.func,
  }),
};

export default FormEditProfile;
