import React from "react";
import { Swiper, SwiperSlide } from "framework7-react";
import Testimoni from "./testimoni";
import styles from "./styles.module.css";

const TestimoniSection = () => {
  const data = [
    {
      imgSrc:
        "https://s3.elastis.id/riska/media/asset/img/pab_testimony_bagoes_sn.jpg",
      name: "Bagoes Suryo Nugroho (SDTNI)",
      description:
        "Kerja di Jakarta tuh bikin capek badan dan batin. Belom lagi kalau kejebak kejebak macet perjalanan pulang dan pergi. Alhamdulillah, RISKA dan Masjid Agung Sunda Kelapa jadi tempat melepas penat dan charge diri di lingkungan orang yang sama sama mau hijrah",
    },
    {
      imgSrc:
        "https://s3.elastis.id/riska/media/asset/img/pab_testimony_siti_fatimah.jpg",
      name: "Siti Fatimah Az-zahra (SDTNI)",
      description:
        "Alhamdulillah selama di RISKA bisa dapat banyak banget ilmu, circle yangg Masya Allah positif dan sefrekuensi sama kita dan kita bisa kontribusi dan menyalurkan apa-apa yang kita bisa! Terimakasih riska sudah mempertemukan aku dengan kakak - kakakku dan abang - abangku yang tidak sedarah tapi insyaAllah searah menuju jannahNya! sayang banyak-banyak💗💗💗",
    },
    {
      imgSrc:
      "https://s3.elastis.id/riska/media/asset/img/pab_testimony_novika_arianti.jpg",
      name: "Novika Arianti (Sister Club)",
      description:
        "Alhamdulillah Allah pertemukan dengan RISKA yang menjadi jawaban untuk kekosongan hati. Selain mendapatkan banyak ilmu pengetahuan yang membuat diri semakin melibatkan Allah dan dekat dengan Al-Qur'an, juga di RISKA pertama kali belajar panahan. RISKA menjadi titik awal sejatuh cinta itu dengan kegiatan sosial dan menjadi bermanfaat untuk orang lain. Daan, yang paling disyukuri adalah lingkungan yang sehangat rumah dan menemukan sahabat yang sudah seperti keluarga. Seru dan asyik bila bertemu, saling memahami, mengingatkan dan mendukung dalam istiqomah.",
    },
    {
      imgSrc:
      "https://s3.elastis.id/riska/media/asset/img/pab_testimony_novieka_laiyla.jpg",
      name: "Novieka Mufidatul Laiyla (Sister Club)",
      description:
        "Masya Allah Tabarakallah dikasih kesempatan sama Allah untuk belajar banyak hal dengan jalur masuk RISKA tuh luar biasa banged. Nggak cuma dapet ilmu di dalam kelas tapi juga pengalaman belajar di luar kelas, ketemu banyak orang dengan banyak karakter tapi tetap sevisi dalam hidup yaitu untuk lebih dekat sama Allah dan lebih kenal Islam. Yah it is another level of my happiness, Alhamdulillah. Semoga dengan pilihan-pilihan kegiatan positif yang RISKA tawarkan pada dunia khususnya di bumi bagian nusantara sekitar jabodetabek maka akan menjadi tombak estafet dakwah para Nabi dan Rasul. Selain itu bisa menjadi jalan Islam dalam membangun peradaban yang lebih baik di akhir zaman. Aamiin. Welcome to our club 🤗",
    },
    {
      imgSrc:
        "https://s3.elastis.id/riska/media/asset/img/pab_testimony_m_faqih.jpg",
      name: "Muhammad Faqih (BMAQ)",
      description:
        "Selama belajar di RISKA itu bukan hanya fokus akhirat tapi dunia juga dapet jadi enggak perlu deh cari komunitas atau wadah lain buat menyalurkan hobi karena disini semua ada",
    },
    {
      imgSrc:
        "https://s3.elastis.id/riska/media/asset/img/pab_testimony_ildza.jpg",
      name: "Ildza Fadhlin Arrizqi (SDIS)",
      description:
        "Di RISKA bukan hanya dapat ilmu yg bermanfaat, tapi disana kita dapat kakak - kakak yang care banget. Selain itu, juga dapat memiliki teman - teman yg Masyallah banget. Di RISKA-lah saya bisa bersosialisasi",
    },
    {
      imgSrc:
        "https://s3.elastis.id/riska/media/asset/img/pab_testimony_aisyah_salwa.jpg",
      name: "Aisyah Salwa Nafiah Sukur (SDIS)",
      description:
        "Masya Allah, senang banget masuk RISKA! Menambah ilmu sekaligus menambah teman baru yang sefrekuensi. Kegiatannya apalagi, seruu banget deh pokoknya. Kakak-kakak pengurus nya juga positif circle. Semoga pertemanan selalu berada di jalan Allah dan bisa kumpul di syurga-Nya. Happy banget pokoknyaa !!!",
    },
  ];

  const params = {
    loop: true,
    slidesPerView: 1,
    breakpoints: {
      // when window width is >= 480px
      480: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      // when window width is >= 640px
      640: {
        slidesPerView: 3,
        spaceBetween: 40,
      },
    },
  };

  return (
    <Swiper navigation params={params} className={styles.swipperContainer}>
      {data.map((data, key) => (
        <SwiperSlide className={styles.swiperSlide} key={key}>
          <Testimoni
            imgSrc={data.imgSrc}
            name={data.name}
            description={data.description}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

TestimoniSection.propTypes = {};

export default TestimoniSection;
